import React from "react";
import Select, { components } from "react-select";
import { ChevronDownIcon } from "@100mslive/react-icons";

const IndicatorsContainer = () => {
  return (
    <div className="flex items-center justify-center">
      <ChevronDownIcon color="#2B3340" width={20} height={20} />
    </div>
  );
};

const Menu = ({ children, ...props }) => {
  return <components.Menu {...props}>{children}</components.Menu>;
};

const Options = props => {
  return <components.Option {...props} />;
};

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>{children}</components.SingleValue>
);

const NormalDropdown = ({
  options,
  defaultValue,
  onBlur,
  onChange,
  containerStyles,
  controlStyles,
  placeholder,
  menuPlacement,
  customOptions = Options,
  selectedValueRenderer = SingleValue,
  innerRef,
  customMenu = Menu,
}) => {
  return (
    <Select
      styles={{
        container: baseStyles => ({
          ...baseStyles,
          minWidth: 113,
          ...containerStyles,
        }),
        control: baseStyles => ({
          ...baseStyles,
          borderColor: "#CED0D9",
          boxShadow: "none",
          borderRadius: 8,
          padding: 0,
          ":focus": {
            borderColor: "#E37712",
            boxShadow: "none",
          },
          ":hover": {
            borderColor: "#CED0D9",
            boxShadow: "none",
          },
          fontSize: 14,
          ...controlStyles,
        }),
        placeholder: baseStyles => ({
          ...baseStyles,
          fontSize: 14,
          color: "#697386",
          fontWeight: "500",
        }),
        valueContainer: baseStyles => ({
          ...baseStyles,
          display: "flex",
          padding: 0,
        }),
      }}
      options={options}
      defaultValue={defaultValue}
      placeholder={placeholder}
      isSearchable={false}
      getOptionLabel={option => option.label}
      getOptionValue={option => option.id}
      onChange={(value, action) => action !== "ignore" && onChange(value?.id)}
      onBlur={onBlur}
      menuPlacement={menuPlacement}
      ref={innerRef}
      components={{
        IndicatorsContainer,
        Option: customOptions,
        SingleValue: selectedValueRenderer,
        Menu: customMenu,
      }}
    />
  );
};

export default NormalDropdown;
