import React, { useState, useRef } from "react";
import {
  useHMSStore,
  selectPeerByID,
  useHMSActions,
  selectAvailableRoleNames,
  selectPeersByRole,
  useParticipantList,
} from "@100mslive/react-sdk";
import {
  // CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@100mslive/react-icons";
import {
  Dialog,
  Button,
  Text,
  // Label,
  // Checkbox,
  Box,
  Flex,
  Dropdown,
} from "@100mslive/roomkit-react";
import { useDropdownSelection } from "./hooks/useDropdownSelection";

const findPeerCounts = ({ participantsByRoles = {} }, roomNameSlug) => {
  const roomGuestsCount =
    participantsByRoles[`${roomNameSlug}-guest`]?.length || 0;
  const roomHostsCount =
    participantsByRoles[`${roomNameSlug}-host`]?.length || 0;

  return [roomGuestsCount, roomHostsCount];
};

const changeRoomDropdownNaming = ({ participantsByRoles = {} }, roleName) => {
  let hosts, guests;
  const currentRoomHosts = participantsByRoles[`${roleName}-host`];
  const currentRoomGuests = participantsByRoles[`${roleName}-guest`];

  if (!currentRoomHosts?.length) {
    hosts = 0;
  } else if (currentRoomHosts?.length > 2) {
    hosts = currentRoomHosts.length;
  } else {
    hosts =
      currentRoomHosts
        ?.map(participant => participant.name.split(" ")[0])
        ?.join(", ") || 0;
  }
  if (!currentRoomGuests?.length) {
    guests = 0;
  } else if (currentRoomGuests?.length > 2) {
    guests = currentRoomGuests.length;
  } else {
    guests =
      currentRoomGuests
        ?.map(participant => participant.name.split(" ")[0])
        ?.join(", ") || 0;
  }

  return ` - ${hosts} - ${guests}`;
};

export const GetBreakoutRoomName = ({
  roleName,
  returnString = false,
  changeRoomDropdown = false,
  participantList = {},
}) => {
  const peers = useHMSStore(selectPeersByRole(roleName + "-host"));
  let breakoutRoomName;
  if (!roleName) {
    return "";
  }
  if (!roleName.includes("waitroom") && peers.length && peers[0].name) {
    const hostNameArray = peers[0].name.split(" ");
    let hostName = hostNameArray[0];
    if (hostNameArray.length > 1) {
      hostName += " " + hostNameArray[1].charAt(0);
    }
    breakoutRoomName = changeRoomDropdown
      ? `BR ${hostName}`
      : `Breakout Room - ${hostName}`;
    if (changeRoomDropdown) {
      const extension = changeRoomDropdownNaming(participantList, roleName);
      breakoutRoomName += extension;
    }
    return returnString ? breakoutRoomName : <>{breakoutRoomName}</>;
  }
  if (roleName.includes("breakroom")) {
    breakoutRoomName =
      (changeRoomDropdown ? "BR " : "Breakout Room - ") +
      (roleName.split("-")[1] || "0");
  } else {
    breakoutRoomName = roleName;
  }

  if (changeRoomDropdown) {
    const extension = changeRoomDropdownNaming(participantList, roleName);
    breakoutRoomName += extension;
  }

  return returnString ? breakoutRoomName : <>{breakoutRoomName}</>;
};

const setDropDownClass = (role, participantList) => {
  const [roomGuestsCount, roomHostsCount] = findPeerCounts(
    participantList,
    role?.replace(/-guest|-host/g, "")
  );

  if (roomHostsCount) {
    if (!roomGuestsCount) {
      return `bg-yocket-blue-300`;
    }
    if (roomGuestsCount > 1) {
      return `bg-yocket-red-300`;
    } else {
      return `bg-yocket-green-300`;
    }
  } else if (roomGuestsCount) {
    return `bg-yocket-orange-300`;
  }

  return ``;
};

export const RoleChangeModal = ({ peerId, onOpenChange }) => {
  const peer = useHMSStore(selectPeerByID(peerId));
  const initialRoles = useHMSStore(selectAvailableRoleNames);
  const [roles, setRoles] = useState(initialRoles);
  const [selectedRole, setRole] = useState(peer?.roleName);
  const [requestPermission] = useState(false);
  const hmsActions = useHMSActions();
  const [open, setOpen] = useState(false);
  const selectionBg = useDropdownSelection();
  const participantList = useParticipantList();
  const ref = useRef();

  React.useEffect(() => {
    if (peer && peer.roleName) {
      const userRoleArray = peer.roleName.split("-");
      const userRole = userRoleArray[userRoleArray.length - 1];
      setRoles(initialRoles.filter(role => role.includes(userRole)));
    } else {
      setRoles(initialRoles);
    }
  }, [peer, initialRoles]);

  if (!peer) {
    return null;
  }

  return (
    <Dialog.Root defaultOpen onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content css={{ width: "min(400px,80%)", p: "$10" }}>
          <Dialog.Title css={{ p: 0 }}>
            <Text variant="h6">Change Room</Text>
            <Text
              variant="body2"
              css={{ fontWeight: 400, mt: "$4", mb: "$8", c: "$textMedEmp" }}
            >{`Change the Room of "${peer.name}" to`}</Text>
          </Dialog.Title>
          <Flex
            align="center"
            css={{
              w: "100%",
              mb: "$10",
            }}
          >
            <Box
              css={{
                position: "relative",
                flex: "1 1 0",
                minWidth: 0,
              }}
            >
              <Dropdown.Root
                open={open}
                onOpenChange={setOpen}
                css={{ width: "100%" }}
              >
                <Dropdown.Trigger
                  asChild
                  css={{
                    border: "1px solid $border_bright",
                    bg: "$surface_bright",
                    r: "$1",
                    p: "$6 $9",
                  }}
                  ref={ref}
                >
                  <Flex
                    align="center"
                    justify="between"
                    css={{ width: "100%" }}
                  >
                    <Text>
                      <GetBreakoutRoomName
                        roleName={selectedRole?.replace(/-guest|-host/g, "")}
                        participantList={participantList}
                        changeRoomDropdown={true}
                      />
                    </Text>
                    {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  </Flex>
                </Dropdown.Trigger>
                <Dropdown.Content
                  align="start"
                  sideOffset={8}
                  css={{ width: ref.current?.clientWidth, zIndex: 1000 }}
                >
                  {roles.map(role => {
                    return (
                      <Dropdown.Item
                        key={role}
                        onSelect={() => setRole(role)}
                        css={{
                          px: "$9",
                          bg: role === selectedRole ? selectionBg : undefined,
                        }}
                        className={setDropDownClass(role, participantList)}
                      >
                        <GetBreakoutRoomName
                          roleName={role?.replace(/-guest|-host/g, "")}
                          participantList={participantList}
                          changeRoomDropdown={true}
                        />
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Content>
              </Dropdown.Root>
            </Box>
          </Flex>
          {/* {!peer.isLocal && (
            <Flex justify="between" css={{ w: "100%", mb: "$10" }}>
              <Label
                htmlFor="requestRoleChangePermission"
                css={{ cursor: "pointer" }}
              >
                Request Permission
              </Label>
              <Checkbox.Root
                checked={requestPermission}
                onCheckedChange={value => setRequestPermission(value)}
                id="requestRoleChangePermission"
              >
                <Checkbox.Indicator>
                  <CheckIcon width={16} height={16} />
                </Checkbox.Indicator>
              </Checkbox.Root>
            </Flex>
          )} */}
          <Flex
            justify="center"
            align="center"
            css={{ width: "100%", gap: "$md" }}
          >
            <Box css={{ width: "50%" }}>
              <Dialog.Close css={{ width: "100%", p: "$4 $8" }} asChild>
                <Button variant="standard" outlined css={{ width: "100%" }}>
                  Cancel
                </Button>
              </Dialog.Close>
            </Box>
            <Box css={{ width: "50%" }}>
              <Button
                variant="primary"
                css={{ width: "100%" }}
                onClick={async () => {
                  await hmsActions.changeRole(
                    peerId,
                    selectedRole,
                    peer.isLocal ? true : !requestPermission
                  );
                  onOpenChange(false);
                }}
              >
                Change
              </Button>
            </Box>
          </Flex>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
