import React from "react";
import { useInView } from "react-intersection-observer";
import {
  selectHMSMessages,
  selectLocalPeerID,
  selectLocalPeerRoleName,
  selectMessagesByPeerID,
  selectMessagesByRole,
  selectPeerByID,
  selectRemotePeers,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import { useUnreadCount } from "./useUnreadCount";
import { EVERYONE } from "../../common/constants";
dayjs.extend(calendar);

const ChatBody = ({ role, peerId, setChatPeerId, localPeerRoleName }) => {
  const storeMessageSelector = role
    ? selectMessagesByRole(role)
    : peerId !== EVERYONE
    ? selectMessagesByPeerID(peerId)
    : selectHMSMessages;
  let messages = useHMSStore(storeMessageSelector) || [];
  let everyoneMessages = useHMSStore(selectHMSMessages) || [];
  if (localPeerRoleName.includes("guest")) {
    messages = [...everyoneMessages];
  }

  const bodyRef = React.createRef();
  const hmsActions = useHMSActions();

  const scrollToBottom = React.useCallback(
    (instant = false) => {
      if (!bodyRef.current) {
        return;
      }
      bodyRef.current.scrollTo({
        top: bodyRef.current.scrollHeight,
        behavior: instant ? "instant" : "smooth",
      });
      hmsActions.setMessageRead(true);
    },
    [bodyRef, hmsActions]
  );

  React.useEffect(() => {
    scrollToBottom(true);
  }, [scrollToBottom]);

  if (!messages.length) {
    return (
      <div className="flex flex-col h-[80%] justify-start p-4 w-full">
        <div className="flex items-center justify-center w-full bg-yocket-neutral-100 rounded p-2 test-sm font-medium text-yocket-neutral-500">
          No messages yet.
        </div>
      </div>
    );
  }
  return (
    <div
      ref={bodyRef}
      className="flex flex-col h-[80%] overflow-auto p-4 gap-6 yocket-scrollbar"
    >
      {messages.map(message => (
        <ChatMessage
          key={message.id}
          message={message}
          setChatPeerId={setChatPeerId}
        />
      ))}
      <ScrollHandler
        scrollToBottom={scrollToBottom}
        role={role}
        peerId={peerId}
      />
    </div>
  );
};

const ChatMessage = React.memo(({ message, setChatPeerId }) => {
  const { ref, inView } = useInView({ threshold: 0.5, triggerOnce: true });
  const recipientPeer = useHMSStore(selectPeerByID(message.recipientPeer));
  const hmsActions = useHMSActions();
  const localPeerId = useHMSStore(selectLocalPeerID);
  const remotePeers = useHMSStore(selectRemotePeers);
  const localPeerRoleName = useHMSStore(selectLocalPeerRoleName);
  let recipientPeerName = recipientPeer?.name || "All";
  if (message?.recipientPeer === localPeerId) {
    recipientPeerName = "You";
  }
  const isSenderNotYou = message.senderName !== "You";
  const [showReplyIcon, setShowReplyIcon] = React.useState(false);

  React.useEffect(() => {
    if (message.id && !message.read && inView) {
      hmsActions.setMessageRead(true, message.id);
    }
  }, [message.read, hmsActions, inView, message.id]);

  const handleNameClick = peerId => {
    const peer = remotePeers.find(peer => peer.id === peerId);
    const sameRoomRole = localPeerRoleName?.includes("guest")
      ? localPeerRoleName.replace("guest", "host")
      : localPeerRoleName.replace("host", "guest");
    if (peerId && peerId !== localPeerId && peer?.roleName === sameRoomRole) {
      setChatPeerId(peerId);
    }
  };

  const handleMessageClick = evt => {
    evt.stopPropagation();
    if (isSenderNotYou) {
      handleNameClick(message.sender);
    }
  };

  React.useEffect(() => {
    const messageCont = document?.getElementById(message.id);
    messageCont?.addEventListener("mouseover", () =>
      setShowReplyIcon(isSenderNotYou)
    );
    messageCont?.addEventListener("mouseleave", () => setShowReplyIcon(false));

    return () => {
      messageCont?.removeEventListener("mouseover", () =>
        setShowReplyIcon(isSenderNotYou)
      );
      messageCont?.removeEventListener("mouseleave", () =>
        setShowReplyIcon(false)
      );
    };
  }, [isSenderNotYou, message.id]);

  return (
    <div
      ref={ref}
      id={message.id}
      className="flex flex-col"
      onClick={handleMessageClick}
    >
      <div className="relative flex text-sm gap-1 items-center w-full">
        <span
          className={
            "font-bold " +
            (message.senderName === "You"
              ? "text-yocket-neutral-900"
              : "text-yocket-orange-700 cursor-pointer")
          }
          onClick={() => handleNameClick(message.sender)}
        >
          {message.senderName}
        </span>
        <span className="font-medium text-yocket-neutral-500">to</span>
        <span
          className={
            "font-bold " +
            (recipientPeerName === "You"
              ? "text-yocket-neutral-900"
              : "text-yocket-orange-700 cursor-pointer")
          }
          onClick={() => handleNameClick(message.recipientPeer)}
        >
          {recipientPeerName}
        </span>
        <span className="ml-2 text-xs font-medium text-yocket-neutral-500">
          {dayjs(message.time).calendar(null, {
            sameDay: "h:mm A",
            lastDay: "[Yesterday] h:mm A",
            lastWeek: "[Last] dddd h:mm A",
            sameElse: "DD/MM/YYYY h:mm A",
          })}
        </span>
        {showReplyIcon ? (
          <img
            src="/svgs/reply.svg"
            className="w-4 h-4 cursor-pointer absolute right-0"
            alt=""
          />
        ) : null}
      </div>
      <p className="text-sm font-medium text-yocket-neutral-500">
        {message.message}
      </p>
    </div>
  );
});

const ScrollHandler = ({ scrollToBottom, role, peerId }) => {
  const { ref, inView } = useInView({ threshold: 0.5 });
  const unreadCount = useUnreadCount({ role, peerId });
  React.useEffect(() => {
    if (inView && unreadCount) {
      scrollToBottom();
    }
  }, [inView, unreadCount, scrollToBottom]);
  return <div ref={ref} style={{ height: 1 }}></div>;
};

export default ChatBody;
