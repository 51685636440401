import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDebounce, useMeasure } from "react-use";
import { FixedSizeList } from "react-window";
import {
  selectAudioTrackByPeerID,
  selectLocalPeerID,
  selectPeerCount,
  selectPeerMetadata,
  selectPermissions,
  useHMSActions,
  useHMSStore,
  useParticipants,
  selectLocalPeerRoleName,
  selectIsPeerAudioEnabled,
  selectDominantSpeaker,
} from "@100mslive/react-sdk";
import {
  ChangeRoleIcon,
  HandRaiseIcon,
  SearchIcon,
  VerticalMenuIcon,
  SpeakerIcon,
} from "@100mslive/react-icons";
import {
  Avatar,
  Box,
  Dropdown,
  Flex,
  Input,
  Slider,
  Text,
  textEllipsis,
} from "@100mslive/roomkit-react";
import { RoleChangeModal, GetBreakoutRoomName } from "../RoleChangeModal";
import { ConnectionIndicator } from "../Connection/ConnectionIndicator";
import { ParticipantFilter } from "./ParticipantFilter";
import IconButton from "../../IconButton";
import {
  useIsSidepaneTypeOpen,
  useSidepaneToggle,
} from "../AppData/useSidepane";
import { APP_DATA, SIDE_PANE_OPTIONS } from "../../common/constants";
import { sortParticipants } from "../../common/utils";
import { isMobile } from "react-device-detect";

export const ParticipantList = () => {
  const localPeerRole = useHMSStore(selectLocalPeerRoleName);
  const isHost = localPeerRole?.includes("host");
  const [filter, setFilter] = useState();
  const { participants, isConnected, peerCount, rolesWithParticipants } =
    useParticipants(filter);
  const [selectedPeerId, setSelectedPeerId] = useState(null);
  const toggleSidepane = useSidepaneToggle(SIDE_PANE_OPTIONS.PARTICIPANTS);
  const onSearch = useCallback(value => {
    setFilter(filterValue => {
      if (!filterValue) {
        filterValue = {};
      }
      filterValue.search = value;
      return { ...filterValue };
    });
  }, []);
  if (peerCount === 0) {
    return null;
  }

  const sortedParticipants = sortParticipants({
    peers: participants,
    sortBy: "roleName",
  });

  return (
    <Fragment>
      <Flex direction="column" css={{ size: "100%" }}>
        <div className="flex justify-center sm:justify-between items-center">
          <p className="text-base sm:text-xl font-bold text-yocket-neutral-900">
            Participants
          </p>
          {isHost ? (
            <ParticipantFilter
              selection={filter}
              onSelection={setFilter}
              isConnected={isConnected}
              roles={rolesWithParticipants}
              localRole={localPeerRole}
            />
          ) : null}
          <button
            type="button"
            onClick={toggleSidepane}
            className="self-center absolute top-4 left-4 sm:relative sm:top-auto sm:left-auto"
          >
            <img src="/svgs/cross.svg" alt="" className="w-6 h-6" />
          </button>
        </div>
        {!isHost ||
        (!filter?.search && sortedParticipants.length === 0) ? null : (
          <ParticipantSearch onSearch={onSearch} />
        )}
        {sortedParticipants.length === 0 && (
          <Flex align="center" justify="center" css={{ w: "100%", p: "$8 0" }}>
            <Text variant="sm">
              {!filter ? "No participants" : "No matching participants"}
            </Text>
          </Flex>
        )}
        {/* Participants List goes here... */}
        <VirtualizedParticipants
          participants={sortedParticipants}
          isConnected={isConnected}
          setSelectedPeerId={setSelectedPeerId}
        />
      </Flex>
      {selectedPeerId && (
        <RoleChangeModal
          peerId={selectedPeerId}
          onOpenChange={value => {
            !value && setSelectedPeerId(null);
          }}
        />
      )}
    </Fragment>
  );
};

export const ParticipantCount = () => {
  const peerCount = useHMSStore(selectPeerCount);
  const toggleSidepane = useSidepaneToggle(SIDE_PANE_OPTIONS.PARTICIPANTS);
  const isParticipantsOpen = useIsSidepaneTypeOpen(
    SIDE_PANE_OPTIONS.PARTICIPANTS
  );
  useEffect(() => {
    if (isParticipantsOpen && peerCount === 0) {
      toggleSidepane();
    }
  }, [isParticipantsOpen, peerCount, toggleSidepane]);

  if (peerCount === 0) {
    return null;
  }
  return (
    <IconButton
      css={{
        w: "auto",
        p: "$5",
        h: "auto",
      }}
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.10)",
        border: "none",
        padding: isMobile && 12,
        width: isMobile && "auto",
        height: isMobile && "auto",
      }}
      onClick={() => {
        if (peerCount > 0) {
          toggleSidepane();
        }
      }}
      active={!isParticipantsOpen}
      data-testid="participant_list"
    >
      <img src="/svgs/profile-2user.svg" alt="" className="w-6 h-6" />
      <p className="text-yocket-neutral-0 text-xs font-bold ml-1.5">
        {peerCount}
      </p>
    </IconButton>
  );
};

const VirtualizedParticipants = ({
  participants,
  canChangeRole,
  isConnected,
  setSelectedPeerId,
}) => {
  const localPeerRole = useHMSStore(selectLocalPeerRoleName);
  let filteredParticipants = [];
  if (
    window.location.pathname.includes("preview") &&
    !localPeerRole.includes("host")
  ) {
    filteredParticipants = participants.filter(ele =>
      ele.roleName.includes("host")
    );
  } else {
    filteredParticipants = participants;
  }
  const [ref, { width, height }] = useMeasure();
  return (
    // Filter participants based on Location...
    <Box ref={ref} css={{ flex: "1 1 0", marginTop: "32px" }}>
      <FixedSizeList
        itemSize={68}
        itemCount={filteredParticipants.length}
        width={width}
        height={height}
      >
        {({ index, style }) => (
          <div style={style} key={filteredParticipants[index].id}>
            <Participant
              peer={filteredParticipants[index]}
              localPeerRole={localPeerRole}
              canChangeRole={canChangeRole}
              showActions={isConnected}
              onParticipantAction={setSelectedPeerId}
            />
          </div>
        )}
      </FixedSizeList>
    </Box>
  );
};
const Participant = ({
  peer,
  canChangeRole,
  showActions,
  onParticipantAction,
  localPeerRole,
}) => {
  return (
    <Flex
      key={peer.id}
      css={{ w: "100%", py: "$3" }}
      align="center"
      data-testid={"participant_" + peer.name}
    >
      <Avatar
        name={peer.name?.substring(0, 1)}
        className="w-11 h-11 !text-base"
      />
      <Flex direction="column" css={{ flex: "1 1 0", gap: 4, marginLeft: 8 }}>
        <div className="flex">
          <Text
            className="!text-sm !font-bold"
            css={{ ...textEllipsis(100) }}
            color="black"
            title={peer.name}
          >
            {peer.name}
          </Text>
          &nbsp;
          <span className="text-sm font-bold">
            {peer.roleName.includes("host")
              ? peer.roleName.includes("waitroom")
                ? "(Host)"
                : "(Advisor)"
              : ""}
          </span>
        </div>
        <p
          className="text-sm font-medium text-yocket-neutral-500"
          title={GetBreakoutRoomName({
            roleName: peer.roleName.replace(/-guest|-host/g, ""),
            returnString: true,
          })}
          style={{ ...textEllipsis(150) }}
        >
          <GetBreakoutRoomName
            roleName={peer.roleName.replace(/-guest|-host/g, "")}
          />
        </p>
      </Flex>
      {showActions && (
        <ParticipantActions
          peerId={peer.id}
          role={peer.roleName}
          localPeerRole={localPeerRole}
          onSettings={() => {
            onParticipantAction(peer.id);
          }}
          canChangeRole={canChangeRole}
        />
      )}
    </Flex>
  );
};

/**
 * shows settings to change for a participant like changing their role
 */
const ParticipantActions = React.memo(
  ({ onSettings, peerId, role, localPeerRole }) => {
    const actions = useHMSActions();
    const isHandRaised = useHMSStore(selectPeerMetadata(peerId))?.isHandRaised;
    const canChangeRole = useHMSStore(selectPermissions)?.changeRole;
    const localPeerId = useHMSStore(selectLocalPeerID);
    const shouldShowMoreActions = canChangeRole;
    const isAudioMuted = !useHMSStore(selectIsPeerAudioEnabled(peerId));
    const dominantSpeaker = useHMSStore(selectDominantSpeaker);
    const toggleChat = useSidepaneToggle(SIDE_PANE_OPTIONS.CHAT);
    const isDominantSpeaker = dominantSpeaker?.id === peerId;
    const showMsgIcon =
      (localPeerRole?.includes("guest")
        ? role === localPeerRole?.replace("guest", "host")
        : role === localPeerRole?.replace("host", "guest") ||
          role === localPeerRole) && peerId !== localPeerId;

    const openChatWithPeerSelected = React.useCallback(() => {
      toggleChat();
      if (peerId) {
        actions.setAppData(APP_DATA.chatPeer, peerId);
      }
    }, [actions, peerId, toggleChat]);

    return (
      <Flex
        align="center"
        css={{ flexShrink: 0, gap: shouldShowMoreActions ? "8px" : "24px" }}
      >
        {isAudioMuted ? (
          <img
            src="/svgs/microphone-slash-black.svg"
            className="w-6 h-6"
            alt=""
          />
        ) : null}
        {isDominantSpeaker ? (
          <div className="flex justify-evenly items-center rounded-full bg-yocket-orange-700 w-6 h-6">
            <span className="w-1 h-1.5 bg-yocket-neutral-0 rounded-full animate__animated animate__zoomIn animate__infinite animate__fast" />
            <span className="w-1 h-2.5 bg-yocket-neutral-0 rounded-full animate__animated animate__zoomIn animate__infinite animate__faster" />
            <span className="w-1 h-1.5 bg-yocket-neutral-0 rounded-full animate__animated animate__zoomIn animate__infinite animate__slow" />
          </div>
        ) : null}
        <ConnectionIndicator peerId={peerId} />
        {isHandRaised && <HandRaiseIcon />}
        {showMsgIcon ? (
          <button type="button" onClick={() => openChatWithPeerSelected()}>
            <img src="/svgs/message-black.svg" className="w-6 h-6" alt="" />
          </button>
        ) : null}
        {shouldShowMoreActions && (
          <ParticipantMoreActions
            onRoleChange={onSettings}
            peerId={peerId}
            role={role}
          />
        )}
      </Flex>
    );
  }
);

const ParticipantMoreActions = ({ onRoleChange, peerId }) => {
  const canChangeRole = useHMSStore(selectPermissions)?.changeRole;
  const [open, setOpen] = useState(false);
  const isVirtualOfficeSession =
    sessionStorage.getItem("isVirtualOfficeSession") === "true";
  const localPeerId = useHMSStore(selectLocalPeerID);
  return (
    <Dropdown.Root open={open} onOpenChange={value => setOpen(value)}>
      <Dropdown.Trigger
        asChild
        data-testid="participant_more_actions"
        css={{ p: "$2", r: "$0" }}
        tabIndex={0}
      >
        <Text>
          <VerticalMenuIcon />
        </Text>
      </Dropdown.Trigger>
      <Dropdown.Portal>
        <Dropdown.Content align="end" sideOffset={8} css={{ w: "$64" }}>
          {canChangeRole &&
            (!isVirtualOfficeSession || peerId === localPeerId) && (
              <Dropdown.Item onClick={() => onRoleChange(peerId)}>
                <ChangeRoleIcon />
                <Text css={{ ml: "$4" }}>Change Room</Text>
              </Dropdown.Item>
            )}
          <ParticipantVolume peerId={peerId} />
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
};

const ParticipantVolume = ({ peerId }) => {
  const audioTrack = useHMSStore(selectAudioTrackByPeerID(peerId));
  const localPeerId = useHMSStore(selectLocalPeerID);
  const hmsActions = useHMSActions();
  // No volume control for local peer or non audio publishing role
  if (peerId === localPeerId || !audioTrack) {
    return null;
  }

  return (
    <Dropdown.Item css={{ h: "auto" }}>
      <Flex direction="column" css={{ w: "100%" }}>
        <Flex align="center">
          <SpeakerIcon />
          <Text css={{ ml: "$4" }}>
            Volume{audioTrack.volume ? `(${audioTrack.volume})` : ""}
          </Text>
        </Flex>
        <Slider
          css={{ my: "0.5rem" }}
          step={5}
          value={[audioTrack.volume]}
          onValueChange={e => {
            hmsActions.setVolume(e[0], audioTrack?.id);
          }}
        />
      </Flex>
    </Dropdown.Item>
  );
};

export const ParticipantSearch = ({ onSearch, placeholder }) => {
  const [value, setValue] = React.useState("");
  useDebounce(
    () => {
      onSearch(value);
    },
    300,
    [value, onSearch]
  );
  return (
    <Box css={{ p: "$4 0", position: "relative" }}>
      <Box
        css={{
          position: "absolute",
          left: "$4",
          top: "$2",
          transform: "translateY(50%)",
          color: "$textMedEmp",
        }}
      >
        <SearchIcon />
      </Box>
      <Input
        type="text"
        placeholder={placeholder || "Find what you are looking for"}
        css={{ w: "100%", pl: "$14" }}
        value={value}
        onKeyDown={event => {
          event.stopPropagation();
        }}
        onChange={event => {
          setValue(event.currentTarget.value);
        }}
      />
    </Box>
  );
};
