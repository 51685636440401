export function getRandomVirtualBackground() {
  let backgroundList = [
    `${process.env.REACT_APP_CLOUDFRONT_CDN_DOMAIN}/images/pngs/live-call-bg-16-9.png`,
  ];
  // if (process.env["REACT_APP_VIDEO_VB"]) {
  //   let gifList = ["https://www.100ms.live/images/vb-1.gif"];
  //   backgroundList.push(...gifList);

  //   /* //Not Supporting video backgrounds until web worker issue is resolved
  //   let videoList = [
  //     "https://www.100ms.live/images/video-1.mp4",
  //     "https://www.100ms.live/images/video-2.mp4",
  //     "https://www.100ms.live/images/video-5.mp4",
  //     "https://www.100ms.live/images/video-7.mp4",
  //     "https://www.100ms.live/images/video-8.mp4",
  //   ];
  //   backgroundList.push(...videoList);*/
  // }

  // let randomIdx = Math.floor(Math.random() * backgroundList.length);
  const img = document.createElement("img");
  img.alt = "VB";
  img.src = backgroundList[0];
  return img;
  /*} else {
    const videoEl = document.createElement("video");
    videoEl.src = backgroundList[randomIdx];
    return videoEl;
  }*/
}
