import { useEffect } from "react";
import {
  HMSNotificationTypes,
  useHMSNotifications,
  selectAppData,
} from "@100mslive/react-sdk";
import {
  useIsHeadless,
  useSubscribedNotifications,
} from "../AppData/useUISettings";
import {
  SUBSCRIBED_NOTIFICATIONS,
  APP_DATA,
  SIDE_PANE_OPTIONS,
} from "../../common/constants";
import { toast } from "react-toastify";
import ChatMessageNotification from "./ChatMessageNotification";

import { hmsStore } from "../../hms";
import { useSidepaneToggle } from "../AppData/useSidepane";
import { useSetChatPeer } from "../AppData/useChatPeer";

export const MessageNotifications = () => {
  const notification = useHMSNotifications(HMSNotificationTypes.NEW_MESSAGE);
  const isNewMessageSubscribed = useSubscribedNotifications(
    SUBSCRIBED_NOTIFICATIONS.NEW_MESSAGE
  );
  const isChatOpen = () => {
    return (
      hmsStore.getState(selectAppData(APP_DATA.sidePane)) ===
      SIDE_PANE_OPTIONS.CHAT
    );
  };
  const toggleChat = useSidepaneToggle(SIDE_PANE_OPTIONS.CHAT);
  const setChatPeer = useSetChatPeer();
  const isHeadless = useIsHeadless();
  useEffect(() => {
    if (!notification) {
      return;
    }
    console.debug(`[${notification.type}]`, notification);
    toast(
      <ChatMessageNotification
        senderName={notification.data?.senderName}
        message={notification.data?.message}
      />,
      {
        autoClose: 5000,
        type: toast.TYPE.INFO,
        closeButton: false,
        hideProgressBar: true,
        position: toast.POSITION.BOTTOM_CENTER,
        containerId: "Chat",
        icon: false,
        onClick: () => {
          if (!isChatOpen()) {
            toggleChat();
            setChatPeer(notification.data?.sender);
          }
        },
      }
    );
    // if (!isNewMessageSubscribed || notification.data?.ignored || isHeadless) {
    //   return;
    // }
    // ToastBatcher.showToast({ notification });
  }, [
    notification,
    isNewMessageSubscribed,
    isHeadless,
    toggleChat,
    setChatPeer,
  ]);

  return null;
};
