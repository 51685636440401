import React from "react";
import { selectAppData, useHMSStore } from "@100mslive/react-sdk";
import { Box } from "@100mslive/roomkit-react";
import Chat from "../components/Chat/Chat";
import { ParticipantList } from "../components/Header/ParticipantList";
import { StreamingLanding } from "../components/Streaming/StreamingLanding";
import { APP_DATA, SIDE_PANE_OPTIONS } from "../common/constants";
import { isMobile } from "react-device-detect";

const SidePane = ({ css = {}, showOldSidePane = false }) => {
  const sidepane = useHMSStore(selectAppData(APP_DATA.sidePane));
  let ViewComponent;
  if (sidepane === SIDE_PANE_OPTIONS.PARTICIPANTS) {
    ViewComponent = ParticipantList;
  } else if (sidepane === SIDE_PANE_OPTIONS.CHAT) {
    ViewComponent = Chat;
  } else if (sidepane === SIDE_PANE_OPTIONS.STREAMING) {
    ViewComponent = StreamingLanding;
  }
  if (!ViewComponent) {
    return null;
  }
  const showOldSidePaneLayout = isMobile;
  return (
    <Box
      css={{
        position: showOldSidePaneLayout && "absolute",
        w: showOldSidePaneLayout ? "$100" : "376px",
        h: showOldSidePaneLayout ? "100%" : "calc(100% - 36px)",
        marginTop: !showOldSidePaneLayout && "24px",
        marginBottom: !showOldSidePaneLayout && "12px",
        marginRight: !showOldSidePaneLayout && "24px",
        p: ViewComponent !== Chat && "16px",
        bg: "white",
        r: "$1",
        top: showOldSidePaneLayout && 0,
        right: showOldSidePaneLayout && "$10",
        zIndex: 10,
        boxShadow: "$md",
        ...css,
        "@lg": {
          w: "100%",
          right: 0,
          position: "fixed",
          bottom: 0,
          height: "unset",
          ...(css["@lg"] || {}),
        },
      }}
    >
      <ViewComponent />
    </Box>
  );
};

export default SidePane;
