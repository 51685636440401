import React from "react";
import slugify from "slugify";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const EVENT_TOPICS = [
  {
    event_topic_id: -1,
    name: "All Yocket Events",
    slug: "all-events",
    colorCode: "#F2D210",
    inactive_icon_url:
      "https://d15gkqt2d16c1n.cloudfront.net/images/calendarIcon.svg",
    active_icon_url:
      "https://d15gkqt2d16c1n.cloudfront.net/images/calendar_active.svg",
  },
  {
    event_topic_id: 1,
    name: "Basics of Study Abroad",
    colorCode: "#16A34A",
  },
  {
    event_topic_id: 2,
    name: "University Meet-ups",
    colorCode: "#CD3D36",
  },
  {
    event_topic_id: 3,
    name: "Alumni Networking",
    colorCode: "#00ACBB",
  },
  {
    event_topic_id: 4,
    name: "Test Prep",
    colorCode: "#7C3AED",
  },
  {
    event_topic_id: 5,
    name: "Application Processing",
    colorCode: "#CA8A04",
  },
  {
    event_topic_id: 6,
    name: "Loans & Visa Process",
    colorCode: "#0275D8",
  },
];

const EventCard = ({ event }) => {
  const imageUrl = `${process.env.REACT_APP_CLOUDFRONT_CDN_DOMAIN}/images/events/`;
  const defaultBgUrl = `${process.env.REACT_APP_CLOUDFRONT_CDN_DOMAIN}/images/dashboard/profile/default-bg-image-profile.jpg`;

  const goToEventDescription = () => {
    const eventSlug = slugify(event.name) + "-" + event.event_id;
    let queryUrl = "";
    if (origin === "homePage") {
      queryUrl = `?frq=${event.frequency}`;
      if (event.event_venue_id) {
        queryUrl = queryUrl + `&venue=${event.event_venue_id}`;
      }
    }

    if (event.event_type === "attended") {
      queryUrl = queryUrl + "?attended=1";
    }

    window.top.location.href =
      process.env.REACT_APP_VUE_HYDROGEN_REDIRECT +
      `/events/${eventSlug}${queryUrl}`;

    // Webengage
    window?.webengage?.track("event_details_click", {
      event_id: event.event_id,
      event_name: event.name,
      event_type: event.event_type,
      source_module: "100ms_post_leave_page",
    });
  };

  const getEventVenueType = () => {
    let eventVenueType = "";
    if (event.event_venue_type === "VIRTUAL") {
      eventVenueType = "Online Event/webinar";
    } else if (event.event_venue_type === "PHYSICAL") {
      eventVenueType = `Physical Meetup`;
      if (event.city_name) {
        eventVenueType += `<span className="text-yocket-blue-600"> @${event.city_name}</span>`;
      }
    }
    return eventVenueType;
  };

  const getEventTime = () => {
    if (event.date_range_for_multislot) {
      return event.date_range_for_multislot;
    }
    return `${dayjs(event.starting_time).format(
      "Do MMM, YYYY | hh:mm A"
    )} - ${dayjs(event.starting_time)
      .add(event.duration, "hours")
      .format("hh:mm A")} ${"IST"} `;
  };

  const getVenueTypeIcon = () => {
    let eventIcon = "";
    if (event.event_venue_type === "VIRTUAL") {
      eventIcon = "https://assets.yocket.com/svgs/videoIconLight.svg";
    } else if (event.event_venue_type === "PHYSICAL") {
      eventIcon = "https://assets.yocket.com/svgs/LocationIcon.svg";
    }
    return eventIcon;
  };

  const getEventTopic = () => {
    const event_topic = event.event_topic[0];
    event_topic.colorCode = EVENT_TOPICS.find(
      topic => topic.event_topic_id === event_topic.event_topic_id
    )?.colorCode;
    return event_topic;
  };

  const getSecondaryTopic = () => {
    const topic = event.event_topic.find(
      topic => topic.name !== getEventTopic().name
    );
    return topic?.name || "none";
  };

  return (
    <div
      className="bg-yocket-neutral-0 flex flex-col justify-between px-2.5 pt-2.5 pb-4 border border-yocket-neutral-200 rounded-xl min-w-[300px] md:max-w-[358px] shadow-md hover:shadow-xl md:cursor-pointer w-full max-h-[25.625rem]"
      onClick={goToEventDescription}
    >
      <div className="relative flex flex-col">
        <img
          className="rounded-xl h-[150px] w-full object-cover"
          src={
            event.cover_img_url ? imageUrl + event.cover_img_url : defaultBgUrl
          }
          alt={event.name}
        />
        <div className="flex flex-col space-y-5 md:mb-5">
          <div className="mt-4 flex flex-col space-y-1.5 w-full min-h-[48px]">
            <div className="flex space-x-1.5 items-center">
              {event.event_topic.length !== 0 ? (
                <p
                  className="text-xs font-bold"
                  style={{ color: getEventTopic().colorCode }}
                >
                  {getEventTopic().name}
                </p>
              ) : (
                <></>
              )}
              {event.event_topic.length === 2 ? (
                <button
                  title={getSecondaryTopic()}
                  className="rounded-xl py-[2px] px-1.5 cursor-pointer bg-yocket-neutral-100 border border-yocket-neutral-200 text-[8px] font-bold"
                >
                  +1
                </button>
              ) : (
                <></>
              )}
            </div>
            <p
              className="font-bold text-base line-clamp-2 cursor-pointer hover:underline min-h-[48px]"
              onClick={e => {
                e.stopPropagation();
                goToEventDescription();
              }}
            >
              {event.name}
            </p>
          </div>
          <div className="flex flex-col space-y-3">
            <div className="flex space-x-2 items-center">
              <img src="https://assets.yocket.com/svgs/Calendar.svg" alt="" />
              <p className="text-sm font-medium text-yocket-neutral-500">
                {getEventTime()}
              </p>
            </div>
            <div
              className="flex space-x-2 items-center relative"
              onClick={() => goToEventDescription()}
            >
              <img src={getVenueTypeIcon()} alt="" />
              <p
                className="text-sm font-medium text-yocket-neutral-500"
                dangerouslySetInnerHTML={{ __html: getEventVenueType() }}
              />
            </div>
          </div>
        </div>
        {event.event_type && event.event_type.toLowerCase() === "live" ? (
          <div className="w-16 h-9 py-2 px-3 rounded-[25.75rem] bg-yocket-red-400 flex items-center space-x-1 absolute top-2 left-2">
            <div className="w-2.5 h-2.5 border-2 border-yocket-neutral-0 rounded-full animate__animated animate__flash animate__infinite animate__slow" />
            <p className="font-bold text-yocket-neutral-0 text-sm">Live</p>
          </div>
        ) : (
          <></>
        )}
        {event.ordering == 1 ? (
          <img
            src="https://assets.yocket.com/svgs/ribbonStar.svg"
            alt=""
            className={
              "absolute -top-1" +
              (event.free_user_visible
                ? "right-[0.761rem]"
                : "right-[3.306rem]")
            }
            title="Must Attend"
          />
        ) : (
          <></>
        )}
        {!event.free_user_visible ? (
          <img
            src="https://assets.yocket.com/svgs/ribbonCrown.svg"
            alt=""
            className="absolute -top-1 right-[0.761rem]"
            title="Premium Only"
          />
        ) : (
          <></>
        )}
      </div>
      <button
        className="mt-5 px-5 sm:mt-0 py-2.5 md:py-3 bg-yocket-orange-700 shadow text-yocket-neutral-0 font-bold text-sm rounded-md w-full"
        onClick={e => {
          e.stopPropagation();
          goToEventDescription();
        }}
      >
        Know More
      </button>
    </div>
  );
};

export default EventCard;
