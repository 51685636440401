import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSearchParam } from "react-use";
import { Loading, styled } from "@100mslive/roomkit-react";
import { ErrorDialog } from "../primitives/DialogContent";
import { useSetUiSettings, useTokenEndpoint } from "./AppData/useUISettings";
import PreviewContainer from "./Preview/PreviewContainer";
import SidePane from "../layouts/SidePane";
import { useNavigation } from "./hooks/useNavigation";
import * as Cookies from "js-cookie";
import {
  useHMSStore,
  selectRemotePeers,
  selectLocalPeerRoleName,
} from "@100mslive/react-sdk";
import {
  QUERY_PARAM_SKIP_PREVIEW_HEADFUL,
  QUERY_PARAM_NAME,
  QUERY_PARAM_SKIP_PREVIEW,
  QUERY_PARAM_AUTH_TOKEN,
  UI_SETTINGS,
} from "../common/constants";
import { isMobile } from "react-device-detect";

/**
 * query params exposed -
 * skip_preview=true => used by recording and streaming service, skips preview and directly joins
 *                      header and footer don't show up in this case
 * skip_preview_headful=true => used by automation testing to skip preview without impacting the UI
 * name=abc => gives the initial name for the peer joining
 * auth_token=123 => uses the passed in token to join instead of fetching from token endpoint
 * ui_mode=activespeaker => lands in active speaker mode after joining the room
 */

const env = process.env.REACT_APP_ENV;
// Creating Component
const PreviewScreen = React.memo(({ getUserToken }) => {
  const localPeerRole = useHMSStore(selectLocalPeerRoleName);
  const navigate = useNavigation(); // Router like thing
  const tokenEndpoint = useTokenEndpoint(); // SDK setup
  const [, setIsHeadless] = useSetUiSettings(UI_SETTINGS.isHeadless); // SDK setup
  const { roomId: urlRoomId, role: userRole } = useParams(); // from the url
  const [token, setToken] = useState(null);
  const [error, setError] = useState({ title: "", body: "" });
  // way to skip preview for automated tests, beam recording and streaming
  const beamInToken = useSearchParam("token") === "beam_recording"; // old format to remove
  let skipPreview = useSearchParam(QUERY_PARAM_SKIP_PREVIEW) === "true";
  // use this field to join directly for quick testing while in local
  const directJoinHeadfulFromEnv =
    process.env.REACT_APP_HEADLESS_JOIN === "true";
  const directJoinHeadful =
    useSearchParam(QUERY_PARAM_SKIP_PREVIEW_HEADFUL) === "true" ||
    directJoinHeadfulFromEnv;
  skipPreview = skipPreview || beamInToken || directJoinHeadful;
  const initialName =
    useSearchParam(QUERY_PARAM_NAME) || (skipPreview ? "Beam" : "");
  let authToken = useSearchParam(QUERY_PARAM_AUTH_TOKEN);
  let location = useLocation();
  const peers = useHMSStore(selectRemotePeers);
  const [userData, setUserData] = useState();
  useEffect(() => {
    window.sessionStorage.setItem(
      "_jn_lc_lk",
      location.pathname + location.search
    );
    if (authToken) {
      setToken(authToken);
      return;
    }
    if (!tokenEndpoint || !urlRoomId) {
      return;
    }
    const getTokenFn = () => {
      try {
        const search = location.search;
        const token = new URLSearchParams(search).get("auth");
        setToken(token);
      } catch (err) {
        setError(convertPreviewError(error));
      }
    };
    getTokenFn();
  }, [tokenEndpoint, urlRoomId, getUserToken, userRole, authToken]);

  const getUserDetails = React.useCallback(async () => {
    const token = Cookies.get("video-auth-token");
    if (
      token &&
      localPeerRole &&
      (userRole === "guest" ||
        (localPeerRole && !localPeerRole?.includes("host")))
    ) {
      const response = await fetch(
        process.env.REACT_APP_YOCKET_BACKEND + "/users/details",
        {
          method: "GET",
          headers: {
            Authorization: token,
          },
        }
      );
      if (response.ok) {
        const json = await response.json();
        setUserData(json.data);
      }
    }
  }, [localPeerRole, userRole]);

  useEffect(() => {
    getUserDetails();
  }, [getUserDetails]);

  const onJoin = () => {
    !directJoinHeadful && setIsHeadless(skipPreview);
    let meetingURL = `/meeting/${urlRoomId}`;
    if (userRole) {
      meetingURL += `/${userRole}`;
    }
    navigate(meetingURL);
  };

  if (error.title) {
    return <ErrorDialog title={error.title}>{error.body}</ErrorDialog>;
  }
  return (
    <div className="flex flex-col w-full h-full bg-[#212225] items-center justify-center">
      <img
        src={`${
          process.env.REACT_APP_CLOUDFRONT_CDN_DOMAIN
        }/images/webp/eventPreviewBg${isMobile ? "Mobile" : ""}.webp`}
        alt=""
        className="w-full h-full object-cover"
      />
      <div className="absolute top-auto left-auto flex justify-center">
        {token ? (
          <>
            <PreviewContainer
              initialName={initialName}
              skipPreview={skipPreview}
              localPeerRole={localPeerRole}
              env={env}
              onJoin={onJoin}
              token={token}
              userData={userData}
            />
            {localPeerRole && localPeerRole.includes("host") ? (
              <div
                style={{
                  position: "absolute",
                  overflowWrap: "break-word",
                  top: 0,
                  right: "28px",
                  "@media (maxWidth: 500px)": {
                    display: "none",
                  },
                }}
              >
                <p
                  style={{
                    fontFamily: "Satoshi",
                    fontWeight: "700",
                    fontSize: "20px",
                    borderBottom: "1px solid #FFFFFF",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    marginBottom: "16px",
                    color: "#FFFFFF",
                  }}
                >
                  Hosts
                </p>
                {peers.map(peer =>
                  peer.roleName.includes("host") ? (
                    <div
                      key={peer.id}
                      style={{
                        backgroundColor: "#FFFFFF",
                        paddingRight: "16px",
                        paddingLeft: "16px",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                        borderRadius: "0.75rem",
                        marginTop: "8px",
                        border: "1px solid #FFFFFF",
                      }}
                    >
                      {Object.keys(JSON.parse(peer?.metadata || "{}"))
                        .length ? (
                        <>
                          {JSON.parse(peer?.metadata || "{}").role ? (
                            <p
                              style={{
                                color: "#2B3340",
                              }}
                            >
                              {peer.name},{" "}
                              <span
                                style={{
                                  fontSize: "14px",
                                  color: "#697386",
                                }}
                              >
                                {/* {JSON.parse(peer.metadata).role} */}
                                Advisor
                              </span>
                            </p>
                          ) : null}
                        </>
                      ) : null}
                    </div>
                  ) : null
                )}
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <Loading size={100} />
        )}
        {/* Participant Listing */}
        <SidePane
          css={{
            position: "unset",
            mr: "$10",
            "@lg": { position: "fixed", mr: "$0" },
          }}
        />
      </div>
    </div>
  );
});

// Set this up
const convertPreviewError = error => {
  console.error("[error]", { error });
  if (error.response && error.response.status === 404) {
    return {
      title: "Room does not exist",
      body: ErrorWithSupportLink(
        "We could not find a room corresponding to this link."
      ),
    };
  } else if (error.response && error.response.status === 403) {
    return {
      title: "Accessing room using this link format is disabled",
      body: ErrorWithSupportLink(
        "You can re-enable this from the developer section in Dashboard."
      ),
    };
  } else {
    console.error("Token API Error", error);
    return {
      title: "Error fetching token",
      body: ErrorWithSupportLink(
        "An error occurred while fetching the app token. Please look into logs for more details."
      ),
    };
  }
};

const Link = styled("a", {
  color: "#2f80e1",
});

export const ErrorWithSupportLink = errorMessage => (
  <div>
    {errorMessage} If you think this is a mistake on our side, please create{" "}
    <Link
      target="_blank"
      href="https://github.com/100mslive/100ms-web/issues"
      rel="noreferrer"
    >
      an issue
    </Link>{" "}
    or reach out over{" "}
    <Link
      target="_blank"
      href="https://discord.com/invite/kGdmszyzq2"
      rel="noreferrer"
    >
      Discord
    </Link>
    .
  </div>
);

export default PreviewScreen;
