import React from "react";
import AvatarWithTag from "../AvatarWithTag";
const PeerJoinLeaveNotification = props => {
  return (
    <div className="flex items-center w-full sm:w-max bg-yocket-neutral-800 rounded-xl px-4 py-2 gap-2 shadow-[0px_0px_16px_5px_rgba(33,33,37,0.32)]">
      <AvatarWithTag
        peerFirstName={props.peerFirstName}
        showTag={props.isAdvisor}
      />
      <p className="text-base text-yocket-neutral-0 font-bold sm:whitespace-nowrap">
        {props.text}
      </p>
    </div>
  );
};

export default PeerJoinLeaveNotification;
