import React from "react";
import { Avatar } from "@100mslive/roomkit-react";
import AdvisorTag from "./AdvisorTag";
const AvatarWithTag = ({
  peerFirstName,
  showTag = false,
  tagText = "Advisor",
  className = "!w-[62px] !h-[62px] !text-[25px]",
  tagTextClassName = "text-[10px] text-yocket-neutral-0 font-medium",
  verifiedIconClassName = "w-[13px] h-[13px]",
}) => {
  return (
    <div className="flex flex-col z-0 items-center shrink-0">
      <Avatar name={peerFirstName} className={className} />
      {showTag ? (
        <AdvisorTag
          verifiedIconClassName={verifiedIconClassName}
          tagTextClassName={tagTextClassName}
          tagText={tagText}
          roundedClassName="rounded"
          containerClassName="px-1.5 py-1 gap-1"
          wrapperClassName="-mt-2.5"
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AvatarWithTag;
