import { Loading, Flex } from "@100mslive/roomkit-react";

const FullPageProgress = () => (
  <Flex
    justify="center"
    align="center"
    css={{ size: "100%", backgroundColor: "#212225" }}
  >
    <Loading size={100} />
  </Flex>
);

export default FullPageProgress;
