import React from "react";

const ChatMessageNotification = ({ senderName, message }) => {
  return (
    <div
      className="flex flex-col w-full sm:w-max bg-yocket-neutral-800
     rounded-xl p-4 gap-4 shadow-[0px_0px_16px_5px_rgba(33,33,37,0.32)]"
    >
      <div className="flex gap-2">
        <img src="/svgs/message-orange.svg" alt="" className="w-6 h-6" />
        <p className="text-base font-bold text-yocket-neutral-0">
          {senderName} to You
        </p>
      </div>
      <p className="text-base font-medium text-yocket-neutral-0 text-ellipsis line-clamp-1 max-w-xs">
        {message}
      </p>
    </div>
  );
};

export default ChatMessageNotification;
