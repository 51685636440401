import React from "react";
import NormalDropdown from "../Input/NormalDropdown";
import {
  selectRemotePeers,
  selectLocalPeerRoleName,
  useHMSStore,
  useHMSActions,
} from "@100mslive/react-sdk";
import { Avatar } from "@100mslive/roomkit-react";
import { sortParticipants } from "../../common/utils";
import ChatBody from "./ChatBodyNew";
import TextInput from "../Input/TextInput";
import { useChatDraftMessage } from "../AppData/useChatState";
import { toast } from "react-toastify";
import { useSidepaneToggle } from "../AppData/useSidepane";
import { APP_DATA, EVERYONE, SIDE_PANE_OPTIONS } from "../../common/constants";
import { isMobile } from "react-device-detect";
import { components } from "react-select";
import { useChatPeer } from "../AppData/useChatPeer";

const Chat = () => {
  const actions = useHMSActions();
  const localPeerRoleName = useHMSStore(selectLocalPeerRoleName);
  const chatPeerId = useChatPeer();
  const setChatPeerId = peerId => {
    if (peerId) {
      actions.setAppData(APP_DATA.chatPeer, peerId);
    }
  };
  const toggleChat = useSidepaneToggle(SIDE_PANE_OPTIONS.CHAT);

  return (
    <div className="flex flex-col h-full overflow-hidden">
      <div className="flex p-4 items-center justify-center sm:justify-between border-b border-b-yocket-neutral-200">
        <p className="text-base font-bold text-yocket-neutral-900">Messages</p>
        <div
          className="cursor-pointer absolute top-4 left-4 sm:relative sm:top-auto sm:left-auto"
          onClick={() => toggleChat()}
        >
          <img src="/svgs/cross.svg" alt="" className="w-6 h-6" />
        </div>
      </div>
      <ChatBody
        peerId={chatPeerId}
        setChatPeerId={setChatPeerId}
        localPeerRoleName={localPeerRoleName}
      />
      <ChatFooter
        localPeerRoleName={localPeerRoleName}
        setChatPeerId={setChatPeerId}
        chatPeerId={chatPeerId}
        onSend={() => {}}
      />
    </div>
  );
};

const ChatFooter = ({
  localPeerRoleName,
  setChatPeerId,
  chatPeerId,
  onSend,
}) => {
  const hmsActions = useHMSActions();
  const remotePeers = useHMSStore(selectRemotePeers);
  let filteredPeers = [];
  if (localPeerRoleName?.includes("guest")) {
    filteredPeers = remotePeers.filter(
      peer => peer.roleName === localPeerRoleName.replace("guest", "host")
    );
  } else {
    filteredPeers = remotePeers.filter(
      peer =>
        peer.roleName === localPeerRoleName.replace("host", "guest") ||
        peer.roleName === localPeerRoleName
    );
  }
  const peers = sortParticipants({
    peers: filteredPeers,
  });
  if (
    localPeerRoleName?.includes("host") &&
    localPeerRoleName?.includes("waitroom")
  ) {
    peers.unshift({ name: EVERYONE, id: EVERYONE });
  }
  const [msgInputFocussed, setMsgInputFocussed] = React.useState(false);
  const [draftMessage, setDraftMessage] = useChatDraftMessage();

  const sendMessage = React.useCallback(async () => {
    if (!draftMessage || !draftMessage.trim().length) {
      return;
    }
    try {
      if (chatPeerId && chatPeerId !== EVERYONE) {
        await hmsActions.sendDirectMessage(draftMessage, chatPeerId);
      } else {
        await hmsActions.sendBroadcastMessage(draftMessage);
      }
      setDraftMessage("");
      document.getElementsByName("message-input").item(0).value = "";
      document.getElementsByName("message-input").item(0).style.height = "20px";
      setTimeout(() => {
        onSend();
      }, 0);
    } catch (error) {
      toast.error(error.message);
    }
  }, [chatPeerId, draftMessage, hmsActions, onSend, setDraftMessage]);

  const handleEnterToSend = evt => {
    if (evt.key === "Enter") {
      evt.preventDefault();
      sendMessage();
    }
  };

  const handleMsgInput = evt => {
    setDraftMessage(evt.target.value);
    document.getElementById("message-input-container").style.height = "auto";
    const msgInput = document.getElementsByName("message-input").item(0);
    if (!evt.target.value.length) {
      msgInput.style.height = "20px";
    } else {
      msgInput.style.height =
        (msgInput.scrollHeight > 120 ? 120 : msgInput.scrollHeight) + "px";
    }
  };

  const selectorRef = React.createRef();

  React.useEffect(() => {
    const currSelectedOption = selectorRef.current?.getValue();

    if (
      chatPeerId &&
      (!currSelectedOption.length || currSelectedOption[0]?.id !== chatPeerId)
    ) {
      const toSelectPeer = peers.find(peer => peer.id === chatPeerId);
      selectorRef.current?.setValue(toSelectPeer, "ignore");
      document.getElementsByName("message-input").item(0)?.focus();
    } else if (!chatPeerId && currSelectedOption.length) {
      selectorRef.current?.clearValue();
    }
  }, [chatPeerId, peers, selectorRef]);

  return (
    <div className="flex flex-col p-4 self-end border-t border-t-yocket-neutral-200 gap-2 h-auto w-full">
      <div className="flex items-center w-full gap-2">
        {chatPeerId ? (
          <span className="font-bold text-sm text-yocket-neutral-900">To:</span>
        ) : null}
        <NormalDropdown
          innerRef={selectorRef}
          containerStyles={{
            width: !chatPeerId && "100%",
          }}
          controlStyles={{
            paddingLeft: chatPeerId ? 4 : 12,
            paddingRight: 10,
            borderRadius: chatPeerId ? 40 : 8,
          }}
          menuPlacement="top"
          onChange={value => setChatPeerId(value)}
          placeholder={
            localPeerRoleName?.includes("host")
              ? "Select a participant to chat"
              : "Select a host to chat"
          }
          options={peers}
          defaultValue={chatPeerId}
          customOptions={({ data, selectOption, getValue }) => {
            const selectedValue = getValue();
            return (
              <div
                key={data.id}
                className={
                  "flex gap-2 sm:px-2 py-4 sm:py-[7px] items-center hover:bg-yocket-neutral-100 cursor-pointer border-b border-b-yocket-neutral-200 sm:border-none " +
                  (selectedValue.length && selectedValue[0]?.id === data.id
                    ? "sm:bg-yocket-neutral-200"
                    : "")
                }
                onClick={() => selectOption(data)}
              >
                <Avatar
                  name={data?.name?.substring(0, 1)}
                  className="!text-sm !w-6 !h-6"
                />
                <span className="text-sm font-medium text-yocket-neutral-900">
                  {data.name}
                </span>
              </div>
            );
          }}
          selectedValueRenderer={({ data }) => {
            return (
              <div className="flex gap-2 items-center">
                <Avatar
                  name={data?.name?.substring(0, 1)}
                  className="!text-sm !w-6 !h-6"
                />
                <span className="text-sm font-medium text-yocket-neutral-900">
                  {data?.name}
                </span>
              </div>
            );
          }}
          customMenu={
            isMobile
              ? props => {
                  return (
                    <div className="fixed top-0 left-0 w-full h-full bg-yocket-neutral-900/50">
                      <div className="absolute bottom-0 w-full rounded-t-lg flex flex-col items-center bg-yocket-neutral-0">
                        <div className="h-1 w-12 bg-yocket-neutral-300 rounded-full my-2" />
                        <components.MenuList
                          {...props}
                          className="!w-full !px-4"
                        >
                          {props.children}
                        </components.MenuList>
                      </div>
                    </div>
                  );
                }
              : undefined
          }
        />
      </div>
      {chatPeerId ? (
        <TextInput
          placeholder="Type a message"
          name="message-input"
          onFocus={() => setMsgInputFocussed(true)}
          onBlur={() => setMsgInputFocussed(false)}
          onChange={handleMsgInput}
          onKeyDown={handleEnterToSend}
          defaultValue={draftMessage}
          inputClassName="placeholder:text-sm placeholder:font-medium w-[calc(100%-20px)] text-sm font-medium yocket-scrollbar h-5"
          multiline={true}
          className="rounded-lg py-2.5 pl-3 pr-2.5 border border-yocket-neutral-300 !justify-between focus-within:border-yocket-orange-700 min-h-[40px] "
          rightChild={
            <button type="button" onClick={sendMessage}>
              <img
                src={`/svgs/send-2${msgInputFocussed ? "-orange" : ""}.svg`}
                className="w-5 h-5"
                alt=""
              />
            </button>
          }
        />
      ) : null}
    </div>
  );
};

export default Chat;
