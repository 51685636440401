import React from "react";
import { Input, Button, styled } from "@100mslive/roomkit-react";
import { isStreamingKit } from "../../common/utils";

const PreviewName = ({ name, onChange, onJoin, enableJoin, localPeerRole }) => {
  const formSubmit = e => {
    e.preventDefault();
  };
  return (
    <Form onSubmit={formSubmit}>
      {localPeerRole && localPeerRole.includes("host") ? (
        <Input
          required
          id="name"
          css={{ w: "100%" }}
          value={name}
          placeholder="Enter your name"
          autoFocus
          autoComplete="name"
          disabled={true}
          onChange={onChange}
        />
      ) : (
        ""
      )}
      {localPeerRole && localPeerRole.includes("guest") ? (
        <button
          className="w-full bg-[#E37712] rounded-lg font-bold text-yocket-neutral-0 text-base py-2"
          type="submit"
          disabled={!name || !enableJoin}
          onClick={onJoin}
          style={{
            boxShadow:
              "0px 2px 8px -2px #F2B151, inset 0px -2px 1px rgba(55, 65, 81, 0.15)",
          }}
        >
          {isStreamingKit() ? "Join Studio" : "Join The Call"}
        </button>
      ) : (
        <Button
          type="submit"
          disabled={!name || !enableJoin}
          onClick={onJoin}
          style={{
            backgroundColor: "#00838A",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "16px",
          }}
        >
          {isStreamingKit() ? "Join Studio" : "Join Room"}
        </Button>
      )}
    </Form>
  );
};

const Form = styled("form", {
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: "$4",
});
export default PreviewName;
