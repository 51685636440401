import React, { Fragment } from "react";
import { useAVToggle, parsedUserAgent } from "@100mslive/react-sdk";
import { VideoOffIcon, VideoOnIcon } from "@100mslive/react-icons";
import { Tooltip } from "@100mslive/roomkit-react";
import IconButton from "../IconButton";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";

const isMacOS = parsedUserAgent.getOS().name.toLowerCase() === "mac os";

export const AudioVideoToggle = ({
  inPreview = false,
  inVirtualOfficePreview = false,
}) => {
  const { isLocalVideoEnabled, isLocalAudioEnabled, toggleAudio, toggleVideo } =
    useAVToggle();

  const toggleAudioWrapper = () => {
    toast.success("Microphone " + (isLocalAudioEnabled ? "off" : "on"), {
      autoClose: 500,
      closeButton: false,
      hideProgressBar: true,
      containerId: "Misc",
      bodyClassName:
        "bg-[#E5F5E8] border border-yocket-green-700 rounded-xl !py-4 !px-10 !text-yocket-neutral-900",
      icon: (
        <img
          src={`/svgs/microphone${
            isLocalAudioEnabled ? "-slash" : ""
          }-black.svg`}
          alt=""
          className="w-6 h-6"
        />
      ),
    });

    toggleAudio();
  };
  return (
    <Fragment>
      {toggleAudio ? (
        <Tooltip
          title={
            !isMobile &&
            `Turn ${isLocalAudioEnabled ? "off" : "on"} audio (${
              isMacOS ? "⌘" : "ctrl"
            } + d)`
          }
        >
          <IconButton
            active={isLocalAudioEnabled}
            onClick={toggleAudioWrapper}
            key="toggleAudio"
            data-testid="audio_btn"
            style={{
              backgroundColor: !isLocalAudioEnabled
                ? "#DB4537"
                : "rgba(255, 255, 255, 0.10)",
              borderRadius: 8,
              padding: (isMobile || inPreview) && 12,
              width: (isMobile || inPreview) && 48,
              height: (isMobile || inPreview) && 48,
            }}
          >
            <img
              src={`/svgs/microphone${
                isLocalAudioEnabled
                  ? inVirtualOfficePreview
                    ? "-black"
                    : ""
                  : "-slash"
              }.svg`}
              alt=""
              className="w-6 h-6"
            />
          </IconButton>
        </Tooltip>
      ) : null}
      {toggleVideo ? (
        <Tooltip
          title={`Turn ${isLocalVideoEnabled ? "off" : "on"} video (${
            isMacOS ? "⌘" : "ctrl"
          } + e)`}
        >
          <IconButton
            key="toggleVideo"
            active={isLocalVideoEnabled}
            onClick={toggleVideo}
            data-testid="video_btn"
            style={{
              backgroundColor: !isLocalVideoEnabled
                ? "#DB4537"
                : "rgba(255, 255, 255, 0.10)",
              padding: (isMobile || inPreview) && 12,
              width: (isMobile || inPreview) && 48,
              height: (isMobile || inPreview) && 48,
            }}
          >
            {!isLocalVideoEnabled ? (
              <VideoOffIcon data-testid="video_off_btn" />
            ) : (
              <VideoOnIcon data-testid="video_on_btn" />
            )}
          </IconButton>
        </Tooltip>
      ) : null}
    </Fragment>
  );
};
