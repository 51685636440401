import React, { useState, useEffect, useCallback } from "react";
import {
  usePreviewJoin,
  useHMSStore,
  useAVToggle,
  selectRemotePeers,
  useHMSActions,
  selectLocalPeerRoleName,
} from "@100mslive/react-sdk";
import { Avatar, Loading } from "@100mslive/roomkit-react";
import { PreviewControls } from "./PreviewJoin";
import { useTokenEndpoint } from "../AppData/useUISettings";
import { QUERY_PARAM_AUTH_TOKEN, APP_DATA } from "../../common/constants";
import { useLocation, useParams } from "react-router-dom";
import { useSearchParam } from "react-use";
import * as Cookies from "js-cookie";
import {
  useUserPreferences,
  UserPreferencesKeys,
  defaultPreviewPreference,
} from "../hooks/useUserPreferences";
import { ToastManager } from "../Toast/ToastManager";
import { useNavigation } from "../hooks/useNavigation";
import { ErrorDialog } from "../../primitives/DialogContent";
import { isMobile } from "react-device-detect";

const VirtualOfficePreviewScreen = React.memo(props => {
  const cdnURL = process.env.REACT_APP_CLOUDFRONT_CDN_DOMAIN;
  const env = process.env.REACT_APP_ENV;
  const tokenEndpoint = useTokenEndpoint(); // SDK setup
  const { roomId: urlRoomId, role: userRole } = useParams(); // from the url
  const navigate = useNavigation();
  const [uiSettingsPreference = {}] = useUserPreferences(
    UserPreferencesKeys.UI_SETTINGS
  );
  const localPeerRole = useHMSStore(selectLocalPeerRoleName);
  const peers = useHMSStore(selectRemotePeers);

  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);
  const [userData, setUserData] = useState();
  const [error, setError] = useState({ title: "", body: "" });
  const [name, setName] = useState("");
  let location = useLocation();
  let authToken = useSearchParam(QUERY_PARAM_AUTH_TOKEN);
  const hmsActions = useHMSActions();

  const search = useLocation().search;
  const advisorName = new URLSearchParams(search).get("advisorName");
  const sourceModule = new URLSearchParams(search).get("source") || "VO_vo";
  const cohortQueueId = new URLSearchParams(search).get("cohortQueueId");
  const [firstHost, setFirstHost] = React.useState();
  const [firstHostPicture, setFirstHostPicture] = React.useState();

  useEffect(() => {
    let newFirstHost = peers?.find(
      peer =>
        peer.roleName.includes("host") &&
        ((advisorName && peer.name === advisorName) ||
          (localPeerRole &&
            peer.roleName === localPeerRole.replace("guest", "host")))
    );
    if (!newFirstHost?.name) {
      newFirstHost = {
        name: "Gaurav Sharma",
      };
    }
    setFirstHost(newFirstHost);
    const newFirstHostPicture =
      newFirstHost?.metadata &&
      JSON.parse(newFirstHost.metadata || "{}").profileImage;
    setFirstHostPicture(newFirstHostPicture);
  }, [advisorName, localPeerRole, peers]);

  useEffect(() => {
    if (localPeerRole && localPeerRole.includes("guest") && sourceModule) {
      window?.parent.postMessage(
        localPeerRole.includes("waitroom") ? "VO_WR_Preview" : "VO_BR_Preview",
        process.env.REACT_APP_YOCKET_BACKEND.toString().replace(
          process.env.ENVIRONMENT === "prod" ? "api." : "api",
          ""
        )
      );
    }
  }, [localPeerRole, sourceModule]);

  useEffect(() => {
    window.sessionStorage.setItem(
      "_jn_lc_lk",
      location.pathname + location.search
    );
    if (authToken) {
      setToken(authToken);
      return;
    }
    if (!tokenEndpoint || !urlRoomId) {
      return;
    }
    const getTokenFn = () => {
      try {
        const search = location.search;
        const token = new URLSearchParams(search).get("auth");
        setToken(token);
      } catch (err) {
        setError(convertPreviewError(error));
      }
    };
    getTokenFn();
  }, [
    tokenEndpoint,
    urlRoomId,
    props.getUserToken,
    userRole,
    authToken,
    location.pathname,
    location.search,
    error,
  ]);

  const getUserDetails = React.useCallback(async () => {
    const token = Cookies.get("video-auth-token");
    if (
      token &&
      localPeerRole &&
      (userRole === "guest" ||
        (localPeerRole && !localPeerRole?.includes("host")))
    ) {
      const response = await fetch(
        process.env.REACT_APP_YOCKET_BACKEND + "/users/details",
        {
          method: "GET",
          headers: {
            Authorization: token,
          },
        }
      );
      if (response.ok) {
        const json = await response.json();
        setUserData(json.data);
      }
    }
  }, [localPeerRole, userRole]);

  useEffect(() => {
    getUserDetails();
  }, [getUserDetails]);

  const [previewPreference, setPreviewPreference] = useUserPreferences(
    UserPreferencesKeys.PREVIEW,
    defaultPreviewPreference
  );

  const { isLocalAudioEnabled, isLocalVideoEnabled, toggleAudio } =
    useAVToggle();
  //   const [previewError, setPreviewError] = useState(false);
  const { enableJoin, preview, join } = usePreviewJoin({
    name,
    token,
    initEndpoint: env ? `https://${env}-init.100ms.live/init` : undefined,
    initialSettings: {
      isAudioMuted: previewPreference.isAudioMuted,
      isVideoMuted: previewPreference.isVideoMuted,
    },
    metadata: JSON.stringify({
      ...userData,
      source_module: sourceModule,
      cohort_queue_id: cohortQueueId,
      device_source: isMobile ? "pwa" : "w",
    }),
    captureNetworkQualityInPreview: true,
    handleError: (_, method) => {
      if (method === "preview") {
        // setPreviewError(true);
      }
    },
  });

  useEffect(() => {
    if (token) {
      setName(userRole === "guest" && userData?.name);
      preview();
    }
  }, [preview, token, userData?.name, userRole]);

  const savePreferenceAndJoin = useCallback(() => {
    const onJoin = () => {
      let meetingURL = `/meeting/${urlRoomId}`;
      if (userRole) {
        meetingURL += `/${userRole}`;
      }
      navigate(meetingURL);
    };
    setPreviewPreference({
      name,
      isAudioMuted: !isLocalAudioEnabled,
      isVideoMuted: !isLocalVideoEnabled,
    });
    //#region guest settings on join
    if (!localPeerRole.includes("host")) {
      isLocalAudioEnabled &&
        toggleAudio() &&
        ToastManager.addToast({ title: "You're muted" });

      const updatedUiSettings = {
        ...uiSettingsPreference,
        [APP_DATA.subscribedNotifications]: {
          ...uiSettingsPreference[APP_DATA.subscribedNotifications],
          PEER_JOINED: false,
          PEER_LEFT: false,
          METADATA_UPDATED: false,
        },
      };
      hmsActions.setAppData(
        APP_DATA.subscribedNotifications,
        updatedUiSettings[APP_DATA.subscribedNotifications],
        true
      );
    }
    //#endregion guest settings on join
    join();
    !localPeerRole.includes("host") && isLocalAudioEnabled && toggleAudio();
    onJoin && onJoin();
  }, [
    setPreviewPreference,
    name,
    isLocalAudioEnabled,
    isLocalVideoEnabled,
    localPeerRole,
    join,
    toggleAudio,
    urlRoomId,
    userRole,
    navigate,
    uiSettingsPreference,
    hmsActions,
  ]);

  React.useEffect(() => {
    let timeout;

    timeout = setTimeout(() => {
      if (name && enableJoin) {
        savePreferenceAndJoin();
        setLoading(false);
      }
    }, 1500);

    // if (progress < 100 && firstHost?.name) {
    //   timeout = setTimeout(() => setProgress(progress + 1), 100);
    // } else if (name && enableJoin) {
    //   savePreferenceAndJoin();
    // }

    return () => clearTimeout(timeout);
  }, [name, enableJoin, savePreferenceAndJoin, firstHost]);

  if (error.title) {
    return <ErrorDialog title={error.title}>{error.body}</ErrorDialog>;
  }

  return (
    <div className="relative  h-screen">
      <img
        src={
          cdnURL +
          (isMobile
            ? "/images/webp/voBackMobile.webp"
            : "/images/webp/Back.webp")
        }
        className="w-full h-full"
        alt=""
      />
      <div className="absolute flex items-center w-full h-full z-10 top-0 left-0">
        <div className="flex items-center justify-center grow">
          <Loading color="orange" size={80} />
        </div>
        {/* <div className="flex flex-col w-full h-full p-4 sm:p-6">
          <img
            src="https://assets.yocket.com/svgs/yocketFullLogo.svg"
            alt="yocket_logo"
            className="h-12 w-[111.65px] self-center mb-6 sm:mb-0 sm:fixed sm:top-6 sm:left-6 sm:h-20 sm:w-[187px]"
          />
          <div className="flex flex-col sm:flex-row h-full">
            {isMobile ? (
              <div className="flex">
                <img
                  src={
                    cdnURL +
                    "/images/pngs/virtualOfficeCharacterHandRaisedMobile1.png"
                  }
                  className="w-14 h-14"
                  alt=""
                />
                <div className="flex text-sm font-medium bg-yocket-orange-700 text-yocket-neutral-0 rounded-b-xl rounded-tr-xl w-full min-h-5 p-2 ml-2">
                  {localPeerRole?.includes("breakroom")
                    ? "Yay! I’ve found an advisor for you! Redirecting you in 10 seconds"
                    : "Your moderator is finding an advisor, connecting you in 10 seconds"}
                </div>
              </div>
            ) : (
              <img
                src={
                  cdnURL + "/images/pngs/virtualOfficeCharacterHandRaised.png"
                }
                className="h-full"
                alt=""
              />
            )}
            <div className="flex flex-col h-[fit-content] sm:-ml-[150px] sm:mt-20 sm:max-w-[808px]">
              {!isMobile ? (
                <div className="flex">
                  <img
                    src={cdnURL + "/images/pngs/virtualOfficeArrow.png"}
                    className="w-[124px] h-[71.5px] self-end -mb-0.5"
                    alt=""
                  />
                  <div
                    className="flex h-fit
                             w-[calc(100%-44px)] -ml-20 rounded-xl bg-gradient-to-r from-[#F68738]
                              to-[#EB6316] p-6 text-xl font-bold text-yocket-neutral-0"
                  >
                    {localPeerRole?.includes("breakroom")
                      ? "Yay! I’ve found an advisor for you! Redirecting you in 10 seconds"
                      : "Your moderator is finding an advisor, connecting you in 10 seconds"}
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="flex self-end my-6 h-1.5 w-full sm:w-[calc(100%-44px)] rounded-full bg-yocket-neutral-0">
                <div
                  className="bg-yocket-orange-700 rounded-full transition-[width]"
                  style={{ width: `${progress}%` }}
                />
              </div>
              <div className="flex flex-col py-6 w-full sm:w-[calc(100%-44px)] self-end bg-gradient-to-r from-yocket-green-50 to-yocket-neutral-0 rounded-[10px]">
                {firstHost ? (
                  <div className="flex flex-col sm:flex-row items-center px-4 sm:px-6 gap-6">
                    <div className="flex flex-col sm:pl-3">
                      <img
                        src="https://assets.yocket.com/svgs/CounsellorStar.svg"
                        className="w-6 h-6 -mb-5"
                        alt=""
                      />
                      <div className="mx-3 rounded-full bg-gradient-to-t from-yocket-orange-300 to-yocket-orange-600">
                        {firstHostPicture ? (
                          <img
                            src={firstHostPicture}
                            className="block w-36 h-36 sm:min-w-[171px] sm:w-[171px] sm:min-h-[171px] sm:h-[171px] rounded-full object-cover"
                            alt=""
                          ></img>
                        ) : (
                          <Avatar
                            name={firstHost?.name || ""}
                            className="w-36 h-36 sm:w-[171px] sm:h-[171px] bg-gradient-to-t from-yocket-orange-300 to-yocket-orange-600 !text-[54px]"
                          />
                        )}
                      </div>
                      <img
                        src="https://assets.yocket.com/svgs/CounsellorStar.svg"
                        className="w-6 h-6 self-end -mt-5"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col items-center sm:items-start">
                      <p className="flex flex-col items-center sm:flex-row sm:items-end sm:justify-start text-base sm:text-2xl w-full font-bold text-yocket-neutral-900 tracking-[0.12px]">
                        <span className="text-xl sm:text-[28px] text-yocket-orange-700">
                          {firstHost?.name || ""}
                        </span>
                        <span className="hidden sm:block w-[1px] h-6 bg-yocket-neutral-300 mx-2 self-center" />
                        <span>
                          {localPeerRole?.includes("breakroom")
                            ? isMobile
                              ? "Senior Counsellor"
                              : "Senior Advisor"
                            : "Moderator"}
                        </span>
                      </p>
                      <p className="text-sm text-center sm:text-left sm:text-base mt-1 font-medium text-yocket-neutral-500">
                        {localPeerRole?.includes("breakroom")
                          ? `Specialist on guiding aspirants to 10+ countries. Has helped over 2000 students aiming for Master’s in ${
                              userData && userData.courses
                            }.`
                          : "Specialist on connecting students to the right advisors! They will help you out & patch you through to your advisor"}
                      </p>
                      <div className="flex items-center sm:items-start bg-[#FEFCE8] py-1 sm:py-3 px-6 rounded-lg mt-2">
                        <span className="text-base sm:text-xl text-[#CA8A04] font-bold">
                          4.96
                        </span>
                        <img
                          src="/stars4_9.png"
                          className="h-4 sm:h-full w-auto ml-1.5"
                          alt=""
                        />
                        <span className="text-sm sm:text-base text-yocket-neutral-900 font-bold ml-3">
                          (4132 students)
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-center">
                    <Loading color="orange" size={50} />
                  </div>
                )}
                <div
                  className="fixed bottom-0 -mx-4 sm:mx-0 pt-5 pb-4 w-full
                 sm:relative sm:bottom-auto flex gap-2.5 items-center justify-center
                  sm:pt-6 sm:pb-0 mt-6 sm:border-t sm:border-t-yocket-neutral-200
                   bg-yocket-neutral-900 sm:bg-transparent"
                >
                  <button
                    type="button"
                    className="yocket-btn !py-3 !text-base sm:w-[312px]"
                    disabled={!name || !enableJoin}
                    onClick={savePreferenceAndJoin}
                  >
                    Join the{" "}
                    {localPeerRole?.includes("breakroom") ? "call" : "Waitroom"}
                  </button>
                  <PreviewControls
                    enableJoin={enableJoin}
                    savePreferenceAndJoin={savePreferenceAndJoin}
                    isVirtualOfficePreview={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
});

// Set this up
const convertPreviewError = error => {
  console.error("[error]", { error });
  if (error.response && error.response.status === 404) {
    return {
      title: "Room does not exist",
      body: ErrorWithSupportLink(
        "We could not find a room corresponding to this link."
      ),
    };
  } else if (error.response && error.response.status === 403) {
    return {
      title: "Accessing room using this link format is disabled",
      body: ErrorWithSupportLink(
        "You can re-enable this from the developer section in Dashboard."
      ),
    };
  } else {
    console.error("Token API Error", error);
    return {
      title: "Error fetching token",
      body: ErrorWithSupportLink(
        "An error occurred while fetching the app token. Please look into logs for more details."
      ),
    };
  }
};

export const ErrorWithSupportLink = errorMessage => <div>{errorMessage}.</div>;

export default VirtualOfficePreviewScreen;
