import {
  selectAppData,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import { useCallback } from "react";
import { APP_DATA } from "../../common/constants";

export const useChatPeer = () => {
  return useHMSStore(selectAppData(APP_DATA.chatPeer));
};

export const useSetChatPeer = () => {
  const hmsActions = useHMSActions();
  return useCallback(
    peerId => {
      hmsActions.setAppData(APP_DATA.chatPeer, peerId);
    },
    [hmsActions]
  );
};
