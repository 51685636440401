import React, { Suspense, useEffect, useState, useCallback } from "react";
import { useMedia } from "react-use";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useParams,
} from "react-router-dom";
import { HMSRoomProvider } from "@100mslive/react-sdk";
import {
  HMSThemeProvider,
  Box,
  styled,
  config as cssConfig,
} from "@100mslive/roomkit-react";
import { Notifications } from "./components/Notifications";
import { Confetti } from "./plugins/confetti";
import { ToastContainer, toast } from "react-toastify";
import { ToastContainer as OldToastContainer } from "./components/Toast/ToastContainer";
import FullPageProgress from "./components/FullPageProgress";
import { KeyboardHandler } from "./components/Input/KeyboardInputManager";
import PostLeave from "./components/PostLeave";
import { AppData } from "./components/AppData/AppData.jsx";
import { ErrorBoundary } from "./components/ErrorBoundary";
import ErrorPage from "./components/ErrorPage";
import { Init } from "./components/init/Init";
import { FeatureFlags } from "./services/FeatureFlags";
import {
  getUserToken as defaultGetUserToken,
  getBackendEndpoint,
} from "./services/tokenService";
import { getRoutePrefix, shadeColor } from "./common/utils";
import { palette } from "./theme.js";
import "./base.css";
import "./index.css";
import "animate.css";
import "react-toastify/dist/ReactToastify.min.css";
import VirtualOfficePreviewScreen from "./components/Preview/VirtualOfficePreviewScreen";
import { isMobile } from "react-device-detect";

const Conference = React.lazy(() => import("./components/conference"));
const PreviewScreen = React.lazy(() => import("./components/PreviewScreen"));

const defaultTokenEndpoint = process.env
  .REACT_APP_TOKEN_GENERATION_ENDPOINT_DOMAIN
  ? `${getBackendEndpoint()}${
      process.env.REACT_APP_TOKEN_GENERATION_ENDPOINT_DOMAIN
    }/`
  : process.env.REACT_APP_TOKEN_GENERATION_ENDPOINT;

const envPolicyConfig = JSON.parse(process.env.REACT_APP_POLICY_CONFIG || "{}");

let appName;
if (window.location.host.includes("localhost")) {
  appName = "localhost";
} else {
  appName = window.location.host.split(".")[0];
}

document.title =
  process.env.REACT_APP_TITLE || `${appName}'s ${document.title}`;

// TODO: remove now that there are options to change to portrait
const getAspectRatio = ({ width, height }) => {
  const host = process.env.REACT_APP_HOST_NAME || window.location.hostname;
  const portraitDomains = (
    process.env.REACT_APP_PORTRAIT_MODE_DOMAINS || ""
  ).split(",");
  if (portraitDomains.includes(host) && width > height) {
    return { width: height, height: width };
  }
  return { width, height };
};
// PARENT COMPONENT - ENTRY POINT
export function EdtechComponent({
  roomId = "",
  tokenEndpoint = defaultTokenEndpoint,
  themeConfig: {
    aspectRatio = "1-1",
    font = "Roboto",
    color = "#E37712",
    theme = "light",
    logo = "",
    headerPresent = "false",
    metadata = "",
    recordingUrl = "",
  },
  getUserToken = defaultGetUserToken,
  policyConfig = envPolicyConfig,
  getDetails = () => {},
}) {
  const { 0: width, 1: height } = aspectRatio
    .split("-")
    .map(el => parseInt(el));
  const [themeType, setThemeType] = useState(theme);
  useEffect(() => {
    window.toggleUiTheme = () => {
      setThemeType(themeType === "dark" ? "light" : "dark");
    };
  }, [themeType]);
  useEffect(() => {
    setThemeType(theme);
  }, [theme]);

  React.useEffect(() => {
    if (isMobile) {
      setViewPortHeight();
      window.addEventListener("resize", setViewPortHeight);
      return () => {
        window.removeEventListener("resize", setViewPortHeight);
      };
    }
  }, []);

  const setViewPortHeight = () => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  const getUserTokenCallback = useCallback(getUserToken, []); //eslint-disable-line
  return (
    <ErrorBoundary>
      <HMSThemeProvider
        themeType={themeType}
        aspectRatio={getAspectRatio({ width, height })}
        theme={{
          colors: {
            ...palette[theme],
            primary_default: color,
            primary_dim: shadeColor(color, -30),
            primary_bright: shadeColor(color, 30),
            primary_disabled: shadeColor(color, 10),
          },
          fonts: {
            sans: [font, "Inter", "sans-serif"],
          },
        }}
      >
        <HMSRoomProvider isHMSStatsOn={FeatureFlags.enableStatsForNerds}>
          <AppData
            appDetails={metadata}
            policyConfig={policyConfig}
            recordingUrl={recordingUrl}
            logo={logo}
            tokenEndpoint={tokenEndpoint}
          />

          <Init />
          <Box
            css={{
              bg: "$mainBg",
              w: "100%",
              ...(headerPresent === "true"
                ? { flex: "1 1 0", minHeight: 0 }
                : { h: "100%" }),
            }}
          >
            <AppRoutes
              getUserToken={getUserTokenCallback}
              getDetails={getDetails}
            />
          </Box>
          <ToastContainer
            enableMultiContainer
            containerId="PeerJoin"
            position={toast.POSITION.BOTTOM_LEFT}
            className="pl-4 !w-max"
            draggable={false}
          />
          <ToastContainer
            enableMultiContainer
            containerId="PeerLeft"
            position={toast.POSITION.TOP_RIGHT}
            className="pr-4 !w-max"
            draggable={false}
          />
          <ToastContainer
            enableMultiContainer
            containerId="Chat"
            position={toast.POSITION.BOTTOM_CENTER}
            className="sm:!w-max"
            draggable={false}
          />
          <ToastContainer
            enableMultiContainer
            containerId="Misc"
            position={toast.POSITION.TOP_CENTER}
            className="!w-screen md:!w-max"
            draggable={false}
            theme="light"
          />
        </HMSRoomProvider>
      </HMSThemeProvider>
    </ErrorBoundary>
  );
}

const RedirectToPreview = ({ getDetails }) => {
  const { roomId, role } = useParams();
  useEffect(() => {
    getDetails();
  }, [roomId]); //eslint-disable-line

  console.error({ roomId, role });

  if (!roomId && !role) {
    return <Navigate to="/" />;
  }
  if (!roomId) {
    return <Navigate to="/" />;
  }
  if (["streaming", "preview", "meeting", "leave"].includes(roomId) && !role) {
    return <Navigate to="/" />;
  }

  return (
    <Navigate to={`${getRoutePrefix()}/preview/${roomId}/${role || ""}`} />
  );
};

const RouteList = ({ getUserToken, getDetails }) => {
  const isMobile = useMedia(cssConfig.media.md);
  return (
    <Routes>
      {/* Preview Screen */}
      <Route path="preview">
        <Route
          path=":roomId/:role"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <PreviewScreen getUserToken={getUserToken} />
            </Suspense>
          }
        />
        <Route
          path="virtual-office/:roomId/:role"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <VirtualOfficePreviewScreen getUserToken={getUserToken} />
            </Suspense>
          }
        />
        {/* Still Preview Screen */}
        <Route
          path=":roomId"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <LogoImg
                src={require("./images/logo-dark.svg")}
                alt="Brand Logo"
                width={isMobile ? "54px" : "90px"}
                height={isMobile ? "24px" : "37px"}
                className="mt-8 ml-4 sm:mt-14 sm:ml-9"
              />
              {/* Customization, this is more useful than above */}
              <PreviewScreen getUserToken={getUserToken} />
            </Suspense>
          }
        />
      </Route>
      {/* Conference Screen */}
      <Route path="meeting">
        <Route
          path=":roomId/:role"
          element={
            <Suspense fallback={<FullPageProgress />}>
              {/* Customization needed */}
              <Conference />
            </Suspense>
          }
        />
        {/* Still Conference Screen */}
        <Route
          path=":roomId"
          element={
            <Suspense fallback={<FullPageProgress />}>
              {/* Customization */}
              <Conference />
            </Suspense>
          }
        />
      </Route>
      {/* Leave Path */}
      <Route path="leave">
        <Route path=":roomId/:role" element={<PostLeave />} />
        <Route
          path=":roomId"
          element={
            <>
              <LogoImg
                src={require("./images/logo-dark.svg")}
                alt="Brand Logo"
                width={isMobile ? "54px" : "100px"}
                height={isMobile ? "24px" : "45px"}
                className="mt-8 ml-4 sm:mt-14 sm:ml-9"
              />
              <PostLeave />
            </>
          }
        />
      </Route>
      {/* Preview Redirection Path */}
      <Route
        path="/:roomId/:role"
        element={<RedirectToPreview getDetails={getDetails} />}
      />
      {/* Preview Redirection Path */}
      <Route
        path="/:roomId/"
        element={<RedirectToPreview getDetails={getDetails} />}
      />
      {/* Error Page */}
      <Route path="*" element={<ErrorPage error="Invalid URL!" />} />
    </Routes>
  );
};

const LogoImg = styled("img", {});

function AppRoutes({ getUserToken, getDetails }) {
  return (
    <Router>
      <OldToastContainer />
      <Notifications />
      <Confetti />
      <KeyboardHandler />
      <Routes>
        <Route
          path="/*"
          element={
            <RouteList getUserToken={getUserToken} getDetails={getDetails} />
          }
        />
        <Route
          path="/streaming/*"
          element={
            <RouteList getUserToken={getUserToken} getDetails={getDetails} />
          }
        />
      </Routes>
    </Router>
  );
}

export default function App() {
  return (
    <EdtechComponent
      themeConfig={{
        aspectRatio: process.env.REACT_APP_TILE_SHAPE,
        theme: process.env.REACT_APP_THEME,
        color: process.env.REACT_APP_COLOR,
        logo: process.env.REACT_APP_LOGO,
        font: process.env.REACT_APP_FONT,
        headerPresent: process.env.REACT_APP_HEADER_PRESENT,
        metadata: process.env.REACT_APP_DEFAULT_APP_DETAILS, // A stringified object in env
      }}
      getUserToken={defaultGetUserToken}
    />
  );
}
