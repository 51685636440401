import React from "react";
import PreviewJoin from "./PreviewJoin";

const PreviewContainer = ({
  token,
  onJoin,
  env,
  skipPreview,
  initialName,
  localPeerRole,
  userData,
}) => {
  return (
    <div>
      <PreviewJoin
        initialName={initialName}
        skipPreview={skipPreview}
        localPeerRole={localPeerRole}
        env={env}
        onJoin={onJoin}
        token={token}
        userData={userData}
      />
    </div>
  );
};

export default PreviewContainer;
