import React from "react";
const AdvisorTag = ({
  wrapperClassName = "-mt-[8px]",
  containerClassName = "px-0.5 py-1 gap-0.5",
  tagTextClassName = "text-[10px] text-yocket-neutral-0 font-medium",
  verifiedIconClassName = "w-[13px] h-[13px]",
  roundedClassName = "rounded-[3px]",
  tagText = "Advisor",
}) => {
  return (
    <div
      className={
        "relative flex items-center justify-center z-10 " +
        wrapperClassName +
        " " +
        roundedClassName
      }
    >
      <div
        className={
          "absolute bg-yocket-neutral-0/10 backdrop-blur-md h-full w-full z-10 " +
          roundedClassName
        }
      />
      <div
        className={
          "flex items-center justify-center z-20 " +
          containerClassName +
          " " +
          roundedClassName
        }
      >
        <img
          src="/svgs/verified.svg"
          className={verifiedIconClassName}
          alt=""
        />
        <p className={tagTextClassName}>{tagText}</p>
      </div>
    </div>
  );
};

export default AdvisorTag;
