import React from "react";
import { styled, Text, textEllipsis } from "@100mslive/roomkit-react";
import { ConnectionIndicator } from "./ConnectionIndicator";
import AdvisorTag from "../AdvisorTag";

const TileConnection = ({
  name,
  peerId,
  hideLabel,
  width,
  peerRoleName,
  isPublishingVideo,
  isHandRaised,
  localPeerRole,
}) => {
  return (
    <Wrapper
      className={`!bottom-0 !left-0 !pl-4 !pb-4 !gap-1 ${
        isPublishingVideo
          ? "bg-gradient-to-t from-black/80 h-16 w-full !rounded-xl !items-end !justify-start"
          : ""
      }`}
    >
      {!hideLabel ? (
        <Text
          css={{
            c: "$textHighEmp",
            ...textEllipsis(width - 60),
          }}
          variant="md"
          className="!text-yocket-neutral-0 !font-medium !text-base !p-0"
        >
          {name}
        </Text>
      ) : null}
      {peerRoleName?.includes("host") &&
      localPeerRole?.includes("guest") &&
      isPublishingVideo ? (
        <AdvisorTag
          wrapperClassName="ml-2"
          containerClassName="!px-1.5 !py-1 !gap-1"
          tagTextClassName="text-sm text-yocket-neutral-0 font-medium !p-0"
          roundedClassName="rounded"
          verifiedIconClassName="w-5 h-5"
        />
      ) : null}
      {isHandRaised ? (
        <div
          className="flex bg-yocket-neutral-0/10 !gap-1 py-1 px-2 !ml-2 items-center rounded-full"
          data-testid="raiseHand_icon_onTile"
        >
          <img src="/svgs/icon-hand.svg" className="w-5 h-5" alt="" />
          <span className="text-sm text-yocket-neutral-0 font-medium !p-0">
            Raised hand
          </span>
        </div>
      ) : null}
      <ConnectionIndicator isTile peerId={peerId} />
    </Wrapper>
  );
};

const Wrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  position: "absolute",
  bottom: "$8",
  left: "$8",
  zIndex: 10,
  borderRadius: "$1",
  "& p,span": {
    p: "$2 $3",
  },
});

export default TileConnection;
