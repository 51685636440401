import React from "react";

const TextInput = ({
  className,
  inputClassName,
  name,
  placeholder,
  onChange,
  onFocus,
  onBlur,
  leftChild,
  rightChild,
  defaultValue,
  value,
  onKeyDown,
  multiline = false,
  rows,
  style,
}) => {
  return (
    <div
      id={name + "-container"}
      className={
        "flex items-center justify-center border rounded-lg border-yocket-neutral-300 p-2.5 h-10 gap-1 " +
        className
      }
      style={style}
    >
      {leftChild ? leftChild : <></>}
      {multiline ? (
        <textarea
          name={name}
          placeholder={placeholder}
          className={
            "border-none outline-none h-full placeholder:text-yocket-neutral-400 resize-none " +
            inputClassName
          }
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          defaultValue={defaultValue}
          rows={rows}
        ></textarea>
      ) : (
        <input
          name={name}
          placeholder={placeholder}
          className={
            "border-none outline-none h-full placeholder:text-yocket-neutral-400 " +
            inputClassName
          }
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          defaultValue={defaultValue}
          value={value}
        />
      )}
      {rightChild ? rightChild : <></>}
    </div>
  );
};

export default TextInput;
