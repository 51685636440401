export const palette = {
  dark: {
    bgPrimary: "#0B0F15",
    bgSecondary: "#1D232B",
    bgTertiary: "#303740",
    textPrimary: "rgba(255,255,255, 0.9)",
    textSecondary: "rgba(255,255,255, 0.72)",
    textDisabled: "rgba(255,255,255, 0.38)",
    textInvert: "rgba(0,0,0,0.9)",
    textMedEmp: "rgba(224, 236, 255, 0.8)",
    iconBtn: "$white",
    iconHoverBg: "rgba(255, 255, 255, 0.25)",
    transparentBg: "rgba(0, 0, 0, 0.22)",
    mainBg: "$black",
    tileBg: "#0F1115",
    previewBg: "#111111",
    statsBg: "rgba(0,0,0,0.75)",
    menuBg: "$grayDark",
    inputBg: "$bgSecondary",
    toastBg: "$menuBg",

    primary_default: "#2572ED",
    primary_bright: "#538DFF",
    primary_dim: "#002D6D",
    primary_disabled: "#004299",
    on_primary_high: "rgba(245, 249, 255, 0.95)",
    on_primary_medium: "rgba(224, 236, 255, 0.8)",
    on_primary_low: "rgba(194, 208, 229, 0.5)",
    secondary_default: "#444954",
    secondary_bright: "#70778B",
    secondary_dim: "#293042",
    secondary_disabled: "#404759",
    on_secondary_high: "#FFFFFF",
    on_secondary_medium: "#D3D9F0",
    on_secondary_low: "#A4ABC0",
    background_default: "#0B0E15",
    background_dim: "#000000",
    surface_default: "#191B23",
    surface_bright: "#272A31",
    surface_brighter: "#2E3038",
    surface_dim: "#11131A",
    on_surface_high: "#EFF0FA",
    on_surface_medium: "#C5C6D0",
    on_surface_low: "#8F9099",
    border_default: "#1D1F27",
    border_bright: "#272A31",
    alert_success: "#36B37E",
    alert_warning: "#FFAB00",
    alert_error_default: "#C74E5B",
    alert_error_bright: "#FFB2B6",
    alert_error_brighter: "#FFEDEC",
    alert_error_dim: "#270005",
  },
  light: {
    bgPrimary: "$white",
    bgSecondary: "#F5F5F5",
    bgTertiary: "rgba(255,255,255, 0.38)",
    textPrimary: "rgba(0,0,0, 0.9)",
    textSecondary: "rgba(0,0,0, 0.72)",
    textInvert: "rgba(255,255,255, 0.9)",
    iconBtn: "#3b3b3b",
    iconHoverBg: "rgba(0, 0, 0, 0.22)",
    transparentBg: "rgba(255, 255, 255, 0.22)",
    mainBg: "$white",
    tileBg: "#F2F2F2",
    previewBg: "#FAFAFA",
    statsBg: "rgba(255,255,255,0.75)",
    // menuBg: "#d9d9d9",
    inputBg: "$tileBg",
    toastBg: "$menuBg",

    // text variants
    textHighEmp: "rgba(0, 0, 0, 0.95)",
    textMedEmp: "rgba(0, 0, 0, 0.8)",
    textDisabled: "rgba(0, 0, 0, 0.4)",

    borderDefault: "#DDE2E9",
    borderLight: "#BCC4D2",

    surfaceDefault: "#EDEFF3",
    surfaceLight: "#F6F7F9",
    surfaceLighter: "#FCFCFD",

    backgroundDefault: "#E0E4EB",
    backgroundDark: "#DADFE7",
    backgroundDarker: "#D4DAE2",

    primary_default: "#2572ED",
    primary_bright: "#538dff",
    primary_dim: "#002D6D",
    primary_disabled: "#004299",
    on_primary_high: "rgba(245, 249, 255, 0.95)",
    on_primary_medium: "rgba(224, 236, 255, 0.8)",
    on_primary_low: "rgba(194, 208, 229, 0.5)",
    secondary_default: "#444954",
    secondary_bright: "#70778B",
    secondary_dim: "#293042",
    secondary_disabled: "#404759",
    on_secondary_high: "#FFFFFF",
    on_secondary_medium: "#D3D9F0",
    on_secondary_low: "#A4ABC0",
    background_default: "#FEFBFF",
    background_dim: "#FFFFFF",
    surface_default: "#EFF0FA",
    surface_bright: "#E7E7F2",
    surface_brighter: "#D8D9E3",
    surface_dim: "#FAF8FF",
    on_surface_high: "#191B23",
    on_surface_medium: "#2E3038",
    on_surface_low: "#A9ABB4",
    border_default: "#ECEDF7",
    border_bright: "#D8D9E3",
    alert_success: "#36B37E",
    alert_warning: "#FFAB00",
    alert_error_default: "#C74E5B",
    alert_error_bright: "#FFB2B6",
    alert_error_brighter: "#FFEDEC",
    alert_error_dim: "#270005",
  },
};

export const defaultTheme = {
  name: "default",
  default: true,
  palette: {},
};
