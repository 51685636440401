import React, { useEffect, useCallback, useState, Fragment } from "react";
import { useLocation, useParams } from "react-router-dom";
// import { useMedia } from "react-use";
import {
  usePreviewJoin,
  selectLocalPeer,
  useHMSStore,
  selectIsLocalVideoEnabled,
  useAVToggle,
  selectRemotePeers,
  useHMSActions,
} from "@100mslive/react-sdk";
import {
  Text,
  Loading,
  useTheme,
  Avatar,
  Flex,
  textEllipsis,
  Box,
  // config as cssConfig,
  StyledVideoTile,
  useBorderAudioLevel,
  Video,
} from "@100mslive/roomkit-react";
import { SettingsIcon } from "@100mslive/react-icons";
import { AudioVideoToggle } from "../AudioVideoToggle";
import SettingsModal from "../Settings/SettingsModal";
import TileConnection from "../Connection/TileConnection";
import PreviewName from "./PreviewName";
import IconButton from "../../IconButton";
import { VirtualBackground } from "../../plugins/VirtualBackground/VirtualBackground";
import {
  useUserPreferences,
  UserPreferencesKeys,
  defaultPreviewPreference,
} from "../hooks/useUserPreferences";
import { APP_DATA } from "../../common/constants";
import { ToastManager } from "../Toast/ToastManager";

const PreviewJoin = ({
  token,
  onJoin,
  env,
  skipPreview,
  initialName,
  localPeerRole,
  userData,
}) => {
  const [previewPreference, setPreviewPreference] = useUserPreferences(
    UserPreferencesKeys.PREVIEW,
    defaultPreviewPreference
  );
  const [uiSettingsPreference = {}] = useUserPreferences(
    UserPreferencesKeys.UI_SETTINGS
  );
  const { role: userRole } = useParams(); // from the url
  const hmsActions = useHMSActions();

  const [name, setName] = useState("");
  const search = useLocation().search;
  // YOCKET INFO
  const username = new URLSearchParams(search).get("name");
  const term = new URLSearchParams(search).get("term");
  const year = new URLSearchParams(search).get("year");
  const degree = new URLSearchParams(search).get("degree");
  const phone = new URLSearchParams(search).get("phone");
  const role = new URLSearchParams(search).get("role");
  const email = new URLSearchParams(search).get("email");
  const profileImage = new URLSearchParams(search).get("pfp");
  const user_uuid = new URLSearchParams(search).get("uuid");
  const stage = new URLSearchParams(search).get("stage");
  const stageAssistance = new URLSearchParams(search).get("stageAssistance");
  const countries = new URLSearchParams(search).get("countries");
  const education = new URLSearchParams(search).get("education");
  const event_id = new URLSearchParams(search).get("event_id");
  const event_venue_id = new URLSearchParams(search).get("event_venue_id");
  const english_test_scores = new URLSearchParams(search).get(
    "english_test_scores"
  );
  const aptitude_test_scores = new URLSearchParams(search).get(
    "aptitude_test_scores"
  );

  const { isLocalAudioEnabled, isLocalVideoEnabled, toggleAudio } =
    useAVToggle();
  const [previewError, setPreviewError] = useState(false);
  const { enableJoin, preview, join } = usePreviewJoin({
    name,
    token,
    initEndpoint: env ? `https://${env}-init.100ms.live/init` : undefined,
    initialSettings: {
      isAudioMuted: skipPreview || previewPreference.isAudioMuted,
      isVideoMuted: skipPreview || previewPreference.isVideoMuted,
    },
    metadata: JSON.stringify({
      year,
      degree,
      phone,
      term,
      role,
      email,
      profileImage,
      user_uuid,
      stage,
      stageAssistance,
      countries,
      education,
      english_test_scores,
      aptitude_test_scores,
      ...userData,
    }),
    captureNetworkQualityInPreview: true,
    handleError: (_, method) => {
      if (method === "preview") {
        setPreviewError(true);
      }
    },
  });
  const savePreferenceAndJoin = useCallback(() => {
    setPreviewPreference({
      name,
      isAudioMuted: !isLocalAudioEnabled,
      isVideoMuted: !isLocalVideoEnabled,
    });
    //#region guest settings on join
    if (!localPeerRole.includes("host")) {
      isLocalAudioEnabled &&
        toggleAudio() &&
        ToastManager.addToast({ title: "You're muted" });

      const updatedUiSettings = {
        ...uiSettingsPreference,
        [APP_DATA.subscribedNotifications]: {
          ...uiSettingsPreference[APP_DATA.subscribedNotifications],
          PEER_JOINED: false,
          PEER_LEFT: false,
          METADATA_UPDATED: false,
        },
      };
      hmsActions.setAppData(
        APP_DATA.subscribedNotifications,
        updatedUiSettings[APP_DATA.subscribedNotifications],
        true
      );
    }
    //#endregion guest settings on join
    join();
    !localPeerRole.includes("host") && isLocalAudioEnabled && toggleAudio();
    onJoin && onJoin();
    if (event_id && event_venue_id && (userData?.user_uuid || user_uuid)) {
      fetch(process.env.REACT_APP_YOCKET_BACKEND + "/events/attendance", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          event_id,
          event_venue_id,
          uuid: userData?.user_uuid || user_uuid,
        }),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setPreviewPreference,
    name,
    isLocalAudioEnabled,
    isLocalVideoEnabled,
    join,
    localPeerRole,
    toggleAudio,
    onJoin,
    event_id,
    event_venue_id,
    userData?.user_uuid,
    user_uuid,
  ]);
  useEffect(() => {
    if (token) {
      setName((userRole === "guest" && userData?.name) || username);
      if (skipPreview) {
        savePreferenceAndJoin();
      } else {
        preview();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, skipPreview, username, userData?.name]);

  const isPCVCPreview = localPeerRole === "guest" || localPeerRole === "host";
  return (
    <div className="">
      <Flex
        align="center"
        justify="center"
        css={{
          "@sm": { width: "100%" },
          flexDirection: "column",
        }}
      >
        {/* This will be shown just to Hosts */}
        {localPeerRole && localPeerRole.includes("host") ? (
          <div className="flex flex-col p-4">
            <PreviewTile
              name={name}
              error={previewError}
              localPeerRole={localPeerRole}
              style={{
                backgroundColor: "none",
              }}
            />
            <Flex
              align="center"
              justify="center"
              css={{
                "@sm": { width: "100%" },
                flexDirection: "row",
                width: "100%",
                marginTop: "2px",
              }}
            >
              <PreviewControls
                enableJoin={enableJoin}
                savePreferenceAndJoin={savePreferenceAndJoin}
              />
              <PreviewName
                name={name}
                onChange={setName}
                enableJoin={enableJoin}
                localPeerRole={localPeerRole}
                onJoin={savePreferenceAndJoin}
              />
            </Flex>
          </div>
        ) : (
          <div className="h-screen w-screen flex flex-col items-center">
            <img
              src="https://assets.yocket.com/svgs/yocketFullLogo.svg"
              alt="yocket_logo"
              className="h-6 w-auto lg:h-[88px] mt-4 lg:mt-0 lg:fixed lg:top-6 lg:left-6"
            />
            <h1 className="text-2xl lg:text-[40px] lg:leading-[52px] text-yocket-neutral-0 font-bold text-center mt-6 max-w-[331px] sm:max-w-none lg:mt-16">
              Dive into the world of studying abroad!
            </h1>
            <div
              className="fixed bottom-8 sm:bottom-12 w-full
                 flex gap-2.5 items-center justify-center"
            >
              <button
                type="button"
                className="yocket-btn !py-3 !text-base w-[212px] sm:w-[312px]"
                disabled={!name || !enableJoin}
                onClick={savePreferenceAndJoin}
              >
                Join the {isPCVCPreview ? " Call" : " Event"}
              </button>
              <PreviewControls
                enableJoin={enableJoin}
                savePreferenceAndJoin={savePreferenceAndJoin}
              />
            </div>
          </div>
        )}
      </Flex>
    </div>
  );
};

// const GuestPreviewContainer = ({ properties }) => {
//   const peers = useHMSStore(selectRemotePeers);
//   const hostPeers = getHostDetails(peers);
//   const firstPeer = hostPeers[0];
//   const mediaQueryMd = cssConfig.media.md;
//   const isMobile = useMedia(mediaQueryMd);
//   return (
//     <div className="flex flex-col mt-16 sm:mt-0">
//       {/* Profile pic and Header text */}
//       <div className="flex flex-col-reverse sm:flex-row">
//         {/* Big text*/}
//         {firstPeer ? (
//           <>
//             {/* Profile Picture */}
//             <ProfilePicture peer={firstPeer} />
//             <div className="w-full sm:w-2/3 host_detail_text flex flex-col space-y-3 px-10 py-6 sm:py-16">
//               <p className="text-[#E37712] text-sm sm:text-xl font-bold text-center sm:text-left">
//                 YOUR ADVISOR IS HERE
//               </p>
//               <p className="text-[#2B3340] text-xl sm:text-4xl font-black text-center sm:text-left">
//                 {firstPeer.name.split(" ")[0]} is ready to answer all your
//                 questions!
//               </p>
//             </div>
//           </>
//         ) : (
//           <>
//             <div className="flex flex-col">
//               <div className="flex flex-col-reverse sm:flex-row">
//                 {!isMobile ? (
//                   <NoHostPicture className="h-full w-full sm:w-1/3" />
//                 ) : (
//                   <></>
//                 )}
//                 <div className="sm:w-2/3 grid grid-cols-1 content-between">
//                   <div className="w-full host_detail_text flex flex-col space-y-3 px-10 py-10 sm:pt-24 sm:py-0">
//                     <p className="text-[#2B3340] text-xl leading-7 sm:leading-[52px] sm:text-[40px] font-bold sm:font-black text-center sm:text-start">
//                       Get all your questions answered right away!
//                     </p>
//                   </div>
//                   {isMobile ? (
//                     <NoHostPicture className="h-full w-full sm:w-1/3" />
//                   ) : (
//                     <></>
//                   )}
//                   <div className="w-full flex space-x-2 items-center sm:border-t py-4 p-4 sm:pl-48 pr-4 rounded-br-lg h-fit">
//                     <PreviewName
//                       name={properties.name}
//                       onChange={properties.setName}
//                       enableJoin={properties.enableJoin}
//                       localPeerRole={properties.localPeerRole}
//                       onJoin={properties.savePreferenceAndJoin}
//                     />
//                     <PreviewControls
//                       enableJoin={properties.enableJoin}
//                       savePreferenceAndJoin={properties.savePreferenceAndJoin}
//                     />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </>
//         )}
//       </div>
//       {/* Host name and Details */}
//       {firstPeer ? (
//         <div className="flex flex-col sm:flex-row">
//           {/* host Details*/}
//           <div className="flex flex-col py-5 mx-16 sm:mx-0 sm:pl-5 sm:w-1/3 border border-[#E3E8EE] sm:border-0 rounded-md">
//             <p className="text-base font-bold text-[#2B3340] text-center sm:text-left">
//               {firstPeer.name}
//             </p>
//             <p className="text-base font-medium text-[#697386] text-center sm:text-left">
//               Student Advisor at Yocket
//             </p>
//           </div>
//           {/* Controls */}
//           <div className="w-full sm:w-2/3 flex space-x-2 items-center sm:border sm:border-l-[#E3E8EE] py-6 sm:py-4 px-4 sm:pl-48 rounded-br-lg">
//             <PreviewName
//               name={properties.name}
//               onChange={properties.setName}
//               enableJoin={properties.enableJoin}
//               localPeerRole={properties.localPeerRole}
//               onJoin={properties.savePreferenceAndJoin}
//             />
//             <PreviewControls
//               enableJoin={properties.enableJoin}
//               savePreferenceAndJoin={properties.savePreferenceAndJoin}
//             />
//           </div>
//         </div>
//       ) : (
//         <></>
//         // <div className="flex flex-col sm:flex-row">
//         //   {/* host Details*/}
//         //   <div className="flex flex-col py-3 mx-16 sm:mx-0 sm:pl-5 sm:w-1/3 border border-[#E3E8EE] sm:border-0 rounded-md">
//         //     <p className="text-base font-bold text-[#2B3340] text-center sm:text-left">
//         //       Dummy User
//         //     </p>
//         //     <p className="text-base font-medium text-[#697386] text-center sm:text-left">
//         //       Student Advisor at Yocket
//         //     </p>
//         //   </div>
//         //   <div className="w-full sm:w-2/3 flex space-x-2 items-center py-4 pl-4 sm:pl-48 pr-4 rounded-br-lg">
//         //     <PreviewName
//         //       name={properties.name}
//         //       onChange={properties.setName}
//         //       enableJoin={properties.enableJoin}
//         //       localPeerRole={properties.localPeerRole}
//         //       onJoin={properties.savePreferenceAndJoin}
//         //     />
//         //     <PreviewControls
//         //       enableJoin={properties.enableJoin}
//         //       savePreferenceAndJoin={properties.savePreferenceAndJoin}
//         //     />
//         //   </div>
//         // </div>
//       )}
//     </div>
//   );
// };

// const ProfilePicture = ({ peer }) => {
//   return (
//     <div className="relative profile_pic w-full px-16 sm:px-0 sm:w-1/3">
//       <div className="h-full">
//         {peer &&
//         peer.metadata &&
//         Object.keys(JSON.parse(peer?.metadata || "{}")).length &&
//         JSON.parse(peer?.metadata || "{}").profileImage ? (
//           <img
//             className="h-full w-full rounded-t-md sm:rounded-t-none sm:rounded-tl-lg"
//             alt="profile_image"
//             src={JSON.parse(peer?.metadata || "{}").profileImage}
//             height="60px"
//             width="60px"
//           ></img>
//         ) : (
//           <div className="bg-[#E3E8EE] p-16 sm:rounded-tl-lg h-full">
//             <Avatar
//               name={peer.name}
//               css={{
//                 p: "$4",
//               }}
//             />
//           </div>
//         )}
//       </div>
//       <div className="absolute flex space-x-1 items-center inset-y-0 right-20 sm:right-3 mt-4 bg-gradient-to-b from-[#E37712] to-[#D9534F] w-fit h-fit px-2 py-1 rounded-sm font-bold text-yocket-neutral-0 text-xs">
//         <div className="relative flex w-3 h-3 items-center bg-gradient-to-b from-[#E37712] to-[#D9534F] rounded-full border-2 border-white">
//           <div className="absolute bg-yocket-neutral-0 inset-x-0 mx-auto rounded-sm h-[4px] w-[4px]"></div>
//         </div>
//         <p>LIVE</p>
//       </div>
//     </div>
//   );
// };

// const NoHostPicture = ({ peer }) => {
//   const mediaQueryMd = cssConfig.media.md;
//   const isMobile = useMedia(mediaQueryMd);
//   return (
//     <div className="flex bg-yocket-neutral-0 sm:bg-[#FDF2E0] items-center mx-auto sm:rounded-l-xl">
//       <img
//         className="sm:h-full sm:w-full rounded-t-md sm:rounded-t-none sm:rounded-tl-lg sm:px-16 py-11 sm:py-24"
//         height="154px"
//         width="154px"
//         alt="profile_image"
//         src={
//           isMobile
//             ? require("../../images/default-live-user-mobile.svg")
//             : require("../../images/default-live-user.svg")
//         }
//       ></img>
//     </div>
//   );
// };

const PreviewTile = ({ name, error, localPeerRole }) => {
  const localPeer = useHMSStore(selectLocalPeer);
  const peers = useHMSStore(selectRemotePeers);
  const borderAudioRef = useBorderAudioLevel(localPeer?.audioTrack);
  const isVideoOn = useHMSStore(selectIsLocalVideoEnabled);
  const hostPeers = peers?.filter(ele => ele.roleName.includes("host")) || [];
  const {
    aspectRatio: { width, height },
  } = useTheme();
  return (
    <StyledVideoTile.Container
      css={{
        aspectRatio: width / height,
        width: "unset",
        height: "min(360px, 60vh)",
        backgroundColor: "rgba(242, 244, 247, 0.9)",
        mt: "$12",
      }}
      ref={borderAudioRef}
    >
      {localPeer ? (
        <>
          <TileConnection name={name} peerId={localPeer.id} hideLabel={true} />
          {isVideoOn ? (
            <Video
              mirror={true}
              trackId={localPeer.videoTrack}
              data-testid="preview_tile"
            />
          ) : (
            <></>
          )}
          {!isVideoOn ? (
            <>
              {localPeerRole.includes("host") ? (
                <StyledVideoTile.AvatarContainer>
                  <Avatar name={name} data-testid="preview_avatar_tile" />
                  <Text css={{ ...textEllipsis("75%") }} variant="body2">
                    {name}
                  </Text>
                </StyledVideoTile.AvatarContainer>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "12px",
                    height: "inherit",
                    overflowY: "auto",
                    width: "100%",
                  }}
                >
                  {hostPeers.length ? (
                    <Box
                      css={{
                        display: "grid",
                        size: "100%",
                        columnGap: "4px",
                        gridTemplateColumns: "auto",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          letterSpacing: "-0.6px",
                          textAlign: "center",
                        }}
                      >
                        You don't have to wait till the last moment. Our
                        advisors are waiting on the call to help clear all your
                        study abroad doubts.{" "}
                      </Text>
                      {peers.map(peer =>
                        peer.roleName.includes("host") ? (
                          <div
                            key={peer.id}
                            style={{
                              paddingRight: "16px",
                              paddingLeft: "16px",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              marginTop: "8px",
                              lineHeight: "12px",
                            }}
                          >
                            {Object.keys(JSON.parse(peer?.metadata || "{}"))
                              .length ? (
                              <>
                                {JSON.parse(peer?.metadata || "{}").role ? (
                                  <Flex
                                    direction="row"
                                    gap="2"
                                    style={{ placeItems: "end" }}
                                  >
                                    <div>
                                      {JSON.parse(peer?.metadata || "{}")
                                        .profileImage ? (
                                        <img
                                          alt="profile_image"
                                          src={
                                            JSON.parse(peer?.metadata || "{}")
                                              .profileImage
                                          }
                                          height="60px"
                                          width="60px"
                                          style={{
                                            borderRadius: "2rem",
                                          }}
                                        ></img>
                                      ) : (
                                        <Avatar
                                          name={peer.name}
                                          css={{
                                            p: "$4",
                                          }}
                                        />
                                      )}
                                    </div>
                                    <p
                                      style={{
                                        fontSize: "14px",
                                        color: "#2B3340",
                                        marginTop: "8px",
                                      }}
                                    >
                                      {peer.name},{" "}
                                      <span
                                        style={{
                                          fontSize: "10px",
                                          color: "#697386",
                                        }}
                                      >
                                        {/* {JSON.parse(peer.metadata).role} */}
                                        Advisor
                                      </span>
                                    </p>
                                  </Flex>
                                ) : null}
                              </>
                            ) : null}
                          </div>
                        ) : null
                      )}
                    </Box>
                  ) : (
                    <Text
                      variant="md"
                      style={{
                        textAlign: "center",
                        marginTop: "16px",
                      }}
                    >
                      Our advisor/s seem to be on a short break, we will inform
                      them and they will be there to assist you in a jiffy, why
                      don't you go ahead and join the call.
                    </Text>
                  )}
                </div>
              )}
            </>
          ) : null}
        </>
      ) : !error ? (
        <Loading size={100} />
      ) : null}
    </StyledVideoTile.Container>
  );
};

export const PreviewControls = ({ isVirtualOfficePreview = false }) => {
  return (
    <Flex
      gap="2"
      css={{
        marginRight: "8px",
      }}
    >
      <Flex css={{ gap: "$4" }}>
        <AudioVideoToggle
          compact
          inPreview={true}
          inVirtualOfficePreview={isVirtualOfficePreview}
        />
        <VirtualBackground />
      </Flex>
      <PreviewSettings />
    </Flex>
  );
};

const PreviewSettings = React.memo(() => {
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      {/* If Host */}
      <IconButton
        data-testid="preview_setting_btn"
        onClick={() => setOpen(value => !value)}
        style={{
          backgroundColor: "#FFFFFF",
          color: "#2B3340",
          border: "1px solid #E3E8EE",
          padding: 12,
          width: 48,
          height: 48,
        }}
      >
        <SettingsIcon />
      </IconButton>
      {open && <SettingsModal open={open} onOpenChange={setOpen} />}
    </Fragment>
  );
});

// const getHostDetails = peers => {
//   const hostPeers = peers.filter(peer => {
//     return peer.roleName.includes("host");
//   });

//   return hostPeers;
// };

export default PreviewJoin;
