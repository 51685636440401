import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastManager } from "./Toast/ToastManager";
import { useNavigation } from "./hooks/useNavigation";
import {
  UserPreferencesKeys,
  useUserPreferences,
  defaultPreviewPreference,
} from "./hooks/useUserPreferences";
import { ArrowRightIcon } from "@100mslive/react-icons";
import EventCard from "./EventCard";
import { isMobile } from "react-device-detect";

const PromoBanner = () => {
  const [loading, setLoading] = useState(false);
  const [showKickStarter, setShowKickStarter] = useState(false);
  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_YOCKET_BACKEND}/upselling/config`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        const {
          data: { data },
        } = response;
        setShowKickStarter(data.live_call?.banner?.kickStarter || false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <>
      {!showKickStarter ? (
        <div className="flex flex-col sm:flex-row sm:space-x-6 items-center mt-14 sm:border sm:border-[#E3E8EE] rounded-lg justify-between py-5 px-6 sm:shadow-md">
          <div className="flex  flex-col sm:flex-row sm:space-x-5">
            <img
              src={require("../images/crown.svg")}
              alt="crown"
              className="h-16 w-16 mx-auto"
            />
            <div className="flex flex-col  text-center sm:text-start space-y-2 sm:space-y-0">
              <p className="text-2xl font-bold text-yocket-neutral-900">
                What's Next?
              </p>
              <p className="text-base font-medium text-yocket-neutral-900 mt-2">
                A counsellor for your entire study abroad journey and guidance
                in every step of the process!
              </p>
            </div>
          </div>
          <div
            className="flex-shrink-0 bg-[#E37712] px-5 py-3 mt-6 sm:mt-0 rounded-lg text-yocket-neutral-0 sm:shadow-md sm:shadow-[#F2B151] cursor-pointer text-sm font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
            onClick={() => {
              let redirectUrl =
                process.env.REACT_APP_VUE_HYDROGEN_REDIRECT + "/premium";
              window.top.location.href = redirectUrl;
              ToastManager.clearAllToast();
              setLoading(true);
            }}
          >
            {loading ? "loading..." : "Find Your Counsellor"}
          </div>
        </div>
      ) : (
        <div className="flex flex-col sm:flex-row sm:space-x-6 items-center mt-3 sm:mt-14 sm:border sm:border-[#E3E8EE] rounded-lg sm:justify-between py-5 px-6 sm:shadow-md">
          <div className="flex flex-col sm:flex-row sm:space-x-5">
            <img
              src={require("../images/university_hat.svg")}
              alt="crown"
              className="h-16 w-16 mx-auto"
            />
            <div className="flex flex-col text-center sm:text-start space-y-2 sm:space-y-0">
              <p className="text-base sm:text-xl font-bold text-yocket-neutral-900">
                Want application fee waivers?
              </p>
              <p className="text-sm sm:text-base font-medium text-yocket-neutral-900 mt-2">
                Apply to 5 Partner Programs with Yocket Kickstarter Plan @ Zero
                application charges!
              </p>
            </div>
          </div>
          <div
            className="flex-shrink-0 bg-[#E37712] px-16 sm:px-5 py-3 mt-6 sm:mt-0 rounded-lg text-yocket-neutral-0 sm:shadow-md sm:shadow-[#F2B151] cursor-pointer text-sm font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
            onClick={() => {
              let redirectUrl =
                process.env.REACT_APP_VUE_HYDROGEN_REDIRECT +
                "/premium/plan/yocket-kickstarter-masters/universities-list";
              window.top.location.href = redirectUrl;
              ToastManager.clearAllToast();
              setLoading(true);
            }}
          >
            {loading ? "loading..." : "Check Partner Programs"}
          </div>
        </div>
      )}
    </>
  );
};

const EventsList = () => {
  const [events, setEvents] = React.useState([]);
  React.useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        process.env.REACT_APP_YOCKET_BACKEND + "/events/list/v2"
      );
      if (response.ok) {
        const json = await response.json();
        setEvents(json.data.planned);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="flex flex-col w-full sm:w-[1106px] mx-auto mt-8 overflow-hidden">
      <div className="flex justify-between w-full px-4 sm:px-0">
        <p className="text-base sm:text-xl text-yocket-neutral-900 font-bold">
          {isMobile
            ? "Upcoming Events"
            : "Upcoming Events You Don't Want To Miss!"}
        </p>
        <div
          className="flex text-sm sm:text-base text-yocket-orange-700 space-x-1 cursor-pointer"
          onClick={() => {
            window.top.location.href =
              process.env.REACT_APP_VUE_HYDROGEN_REDIRECT + "/events";
            ToastManager.clearAllToast();
          }}
        >
          <span>View All</span>
          <ArrowRightIcon className="w-6 h-6" />
        </div>
      </div>
      {events?.length ? (
        <div className="flex sm:flex-wrap gap-4 mt-4 mb-28 h-fit overflow-x-auto px-4 pb-4 sm:pb-4 sm:px-0">
          {events.slice(0, 3).map(eventItem => (
            <EventCard key={eventItem.name} event={eventItem} />
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const PostLeave = () => {
  const navigate = useNavigation();
  const [previewPreference] = useUserPreferences(
    UserPreferencesKeys.PREVIEW,
    defaultPreviewPreference
  );

  const isVirtualOfficeSession =
    sessionStorage.getItem("isVirtualOfficeSession") === "true";
  return (
    <div className="flex flex-col items-center">
      <div className="relative flex items-end bg-yocket-orange-50 w-full pt-[54px]">
        <img
          className="w-full"
          src={
            process.env.REACT_APP_CLOUDFRONT_CDN_DOMAIN +
            `/images/pngs/postCallBuildings${isMobile ? "Mobile" : ""}.png`
          }
          alt=""
        />
        <div className="absolute top-0 left-0 flex flex-col items-center justify-center w-full h-full">
          <img
            src="/svgs/yocket-full-logo.svg"
            alt="yocket-logo"
            className="w-[54px] sm:w-[99px] h-[24px] sm:h-[44px]"
          />
          <p className="text-base sm:text-[28px] font-bold text-yocket-neutral-900 text-center mt-9">
            Thanks {previewPreference.name}!{isMobile ? <br /> : " "}
            {isMobile
              ? "It was great talking to you!"
              : "It was great to have you here!"}
          </p>
          {/* Actions */}
          <div className="flex space-x-4 mt-6">
            {!isVirtualOfficeSession ? (
              <div
                className="px-5 py-2 rounded-lg border border-[#CED1D9] text-yocket-neutral-900 font-bold text-sm cursor-pointer"
                onClick={() => {
                  let previewUrl = window.sessionStorage.getItem("_jn_lc_lk");
                  if (previewUrl) navigate(previewUrl);
                  ToastManager.clearAllToast();
                }}
              >
                Rejoin
              </div>
            ) : (
              <></>
            )}
            <div
              className="px-5 py-2 rounded-lg text-yocket-neutral-0 bg-yocket-neutral-900 font-bold text-sm cursor-pointer"
              onClick={() => {
                let redirectUrl =
                  process.env.REACT_APP_VUE_HYDROGEN_REDIRECT + "/premium";
                window.top.location.href = redirectUrl;
                ToastManager.clearAllToast();
                if (isVirtualOfficeSession) {
                  window?.webengage?.track("VO_PremiumPage");
                }
              }}
            >
              Back to Yocket
            </div>
          </div>
        </div>
      </div>
      <EventsList />
    </div>
  );
};

export default PostLeave;
